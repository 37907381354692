import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { IBorrower } from "../interfaces/borrower";
import { APIOrigin, CancelTransactionOptions } from "../interfaces/encompass";
import { Transaction } from "./transaction";

export class CancelTransaction extends Transaction<CancelTransactionOptions> {
    constructor(
        requestId: string,
        borrower: IBorrower,
        origin: APIOrigin,
    ) {
        super(
            borrower.encompassBorrowerId,
            {
                requestId,
                irsIncomeId: borrower.irsIncomeId || "",
                loanProfessional: origin.origin.originatingParty.pointOfContact,
                status: "Request Submitted",
                requestType: "cancel request",
                instanceId: origin.instanceId,
                encompassBorrowerId: borrower.encompassBorrowerId,
                primaryBorrower: origin.origin.loan.applications[0].id,
                loanNumber: origin.origin.loan.loanNumber,
            },
        );
    }

    public toTransaction(): TransactionRequest<CancelTransactionOptions> {
        return super.toTransaction();
    }
}
