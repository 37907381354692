import { Typography } from "antd";

export const Header = ({
    latestAttempt,
    title,
    withIrs = false,
}: {
    latestAttempt: Date;
    title: string;
    withIrs?: boolean;
}) => (
    <div className="flex-center-between">
        <Typography style={{ fontSize: 16 }}>
            {title}
        </Typography>
        <Typography style={{ fontSize: 16, marginRight: 24 }}>
            Last Checked{withIrs ? ' With The IRS' : ''}: <span style={{ fontWeight: 400 }}>{latestAttempt.toDateString()} {latestAttempt.toLocaleTimeString()}</span>
        </Typography>
    </div>
);
