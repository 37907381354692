/* eslint-disable import/no-cycle */
import { Checkbox, Divider, Form, Tooltip } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import EncompassFileSelector from "../atoms/EncompassFileSelector";
import { RootState } from "../../configureStore";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";
import Icon from "../atoms/Icon";

interface DocumentUploadParams {
    isBusiness?: boolean;
    isReorder?: boolean;
    hasIRS?: boolean;
    isEdit?: boolean;
    index?: number;
    span?: number;
    existing?: boolean;
}

const DocumentUpload = ({
    isBusiness,
    isReorder,
    hasIRS,
    isEdit = false,
    index,
    span = 5,
    existing = false,
}: DocumentUploadParams) => {
    const isEncompass = useSelector<RootState, boolean>(({ encompass }) => Boolean(encompass.loanNumber));
    const enabled = useSelector<RootState, string[] | undefined>(({ borrower: { activeBorrower: ac, borrowers: b }, configuration: { actionReasons: ar } }) => ar[(b[ac || '']?.actionReason?.actionCode) || '']?.enabled);
    const isFix = useSelector<RootState, boolean>(({ borrower: { isFix: isF } }) => isF);

    const txptName = useMemo(() => (index != null ? [index, isBusiness ? "businessTranscriptAuthorization" : "transcriptAuthorization"] : isBusiness ? "businessTranscriptAuthorization" : "transcriptAuthorization"), [index, isBusiness]);
    const txptEnabled = useMemo(() => Boolean(enabled?.length && enabled.includes(String(Array.isArray(txptName) ? txptName[txptName.length - 1] : txptName))), [txptName, enabled]);

    const govName = useMemo(() => (index != null ? [index, isBusiness ? "businessGovernmentIssuedPhotoId" : "governmentIssuedPhotoId"] : isBusiness ? "businessGovernmentIssuedPhotoId" : "governmentIssuedPhotoId"), [index, isBusiness]);
    const govEnabled = useMemo(() => Boolean(enabled?.length && enabled.includes(String(Array.isArray(govName) ? govName[govName.length - 1] : govName))), [govName, enabled]);
    const { isSmall, isMedium } = useWindowDimensions();

    return (
        <div className="form-section" style={{ height: '93%' }}>
            <Tooltip overlayStyle={{ minWidth: 300 }} overlayInnerStyle={{ opacity: 1 }} title={<p className="my-0 px-2">Transcript Authorization must contain a Signed Form 8821 and Signed Consent Form.<br /><p className="mb-0">You can:</p><ul><li className="mt-0">Upload a single document with both forms combined</li><li>Two documents by selecting both Forms your Encompass File Selector at the same time.</li></ul></p>} placement="left"><h5 className="mb-0"><Icon className="mr-2" name="InfoCircleOutlined" color="black" />Upload Documents</h5></Tooltip>
            <p className="mb-4 mt-0"><i>(Accepted file types are pdf, jpg, jpeg and png.)</i></p>
            {
                isBusiness && (
                    <Form.Item name={index != null ? [index, "isSamePerson"] : "isSamePerson"} fieldId="checked">
                        <Checkbox disabled={hasIRS || isEdit}>Borrower and Business Entity Taxpayer are the same person</Checkbox>
                    </Form.Item>
                )
            }
            <Form.Item
                name={txptName}
                label="Transcript Authorization"
                rules={[{ required: !(isEdit || existing), message: 'Transcript Authorization is required.' }]}
                labelCol={{ span }}
                labelAlign="left"
            >
                {isEncompass ? (
                    <EncompassFileSelector
                        fileType={isBusiness ? 8 : 7}
                        isReorder={isReorder}
                        disabled={isFix && !txptEnabled}
                        danger={Boolean(enabled?.length && txptEnabled)}
                        allow={2}
                    />
                ) : <div />}
            </Form.Item>
            {(isSmall || (isEdit && isMedium)) && <Divider className="my-2" style={{ backgroundColor: 'rgba(200, 200, 200, 0.7)' }} />}
            <Form.Item
                name={govName}
                label="Government Issued Photo ID (Optional)"
                labelCol={{ span }}
                labelAlign="left"
            >
                {isEncompass ? (
                    <EncompassFileSelector
                        fileType={isBusiness ? 5 : 2}
                        isReorder={isReorder}
                        disabled={isFix && !govEnabled}
                    />
                ) : <div />}
            </Form.Item>
        </div>
    );
};
export default DocumentUpload;
