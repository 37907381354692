const values: any = {};

const sandbox = {
    has: (key: any) => key in values,

    get: (key: any) => values[key],

    set: (key: any, value: any) => {
        values[key] = value;
    },
};

export default sandbox;
