import { DateTime } from 'luxon';

export interface IRIAForm {
    firstName: string;
    lastName: string;
    email: string;
}

const RIA_SERVICE_NAME = 'Go Plan 101';

const text: string[] = [
    'The Referring Party hereby declares and confirms the following:',
    "Independent Services: The referral of clients from the Referring Party to the Referred Party has no bearing whatsoever on the decisioning with regard to the loan services provided by the Referring Party. The decision to use or not to use the services of the Referred Party does not influence any aspect of the client's loan process or application.",
    "No Financial Advice: The Referring Party has not offered or provided any financial advice to the client in relation to the Referred Party's services or any other investment services or products. The Referring Party has not recommended any specific financial products, services or strategies to the client.",
    'No Market or Economic Discussions: The Referring Party has not engaged in any discussions with the client concerning the market, economic outlooks, or potential returns of any investment opportunities or strategies.',
    'No Contingency: Any loan product or service offered by the Referring Party is not contingent upon the client doing any business with the Referred Party, participating in any investment, or purchasing any financial product or service.',
    'Compliance with RESPA: The Referring Party affirms its familiarity with the Real Estate Settlement Procedures Act (RESPA) and internal firm policies and confirms that it has not violated any RESPA rules or regulations in the referral process or in any other interaction with the client.',
    'Compliance with FINRA and SEC: The Referring Party acknowledges that it is a violation of the Financial Industry Regulatory Authority (FINRA) and the Securities and Exchange Commission (SEC) rules to provide financial advice as an unlicensed individual. The Referring Party confirms that it has not violated those rules and has not provided any form of investment advice to any client involved in this transaction.',
    'Compensation Arrangement for Referral: [MORTGAGE COMPANY NAME] may receive compensation in the form of a referral fee from [RIA SERVICE NAME] for referring clients to their services. This referral fee arrangement has no bearing on the decisioning regarding the loan services provided by [MORTGAGE COMPANY NAME]. Decisions on loan products or services are made independently of whether the client chooses to do business with [RIA SERVICE NAME].',
    'Disclosure of Compensation: The compensation arrangement for referrals is fully disclosed to our clients. The compensation received by [MORTGAGE COMPANY NAME] is solely for the referral of the client and is not based on any action or advice provided by [RIA SERVICE NAME].',
    "Client Best Interest: We only refer clients to [RIA SERVICE NAME] if we believe it is in their best interest. The decision to use or not to use the services of [RIA SERVICE NAME] does not influence any aspect of the client's loan process or application with [MORTGAGE COMPANY NAME].",
    'Regulatory Compliance: The Referring Party affirms its familiarity with SEC, FINRA, and RESPA rules and confirms that it has not violated any of these rules in the referral process or in any other interaction with the client.',
    "Client Information Protection: This referral arrangement does not involve sharing the client's personal information, including Personally Identifiable Information (PII), without the explicit consent of the borrower. The privacy and protection of the borrower's information are upheld, and the Referring Party ensures that any disclosures or sharing of information comply with applicable laws and regulations.",
    'Required Disclosures: [RIA SERVICE NAME] is obligated to provide a disclosure document, such as a Form ADV, outlining their background, experience, and compensation structure during the initial meeting with the client. This disclosure helps ensure transparency about the referred services and potential costs.',
    "Third-Party Involvement: The use of a non-licensed third-party firm as a middleman to facilitate the transaction may occur. This allows [MORTGAGE COMPANY NAME] to offer additional financial services without requiring an investment advisory license, broadening [RIA SERVICE NAME]'s client base through referrals. However, the client should be aware that this could introduce additional fees, which would be disclosed upfront.",
    'By accepting the referral, the client acknowledges their understanding of the above-mentioned points and that they have the discretion to accept or decline the services of the Referred Party. If there are any queries or concerns about this referral process, clients are encouraged to contact Halcyon at info@halcyonsw.com',
    '[MORTGAGE COMPANY NAME]',
    '[DATE]',
];

const riaText = (company: string) => ({
    version: '1.0.0',
    text: text.map((line) => line.replace(/\[MORTGAGE COMPANY NAME\]/g, company).replace(/\[RIA SERVICE NAME\]/g, RIA_SERVICE_NAME).replace(/\[DATE\]/g, DateTime.now().toFormat('MMMM dd, yyyy'))),
});

export default riaText;
