import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { APIOrigin, ResubmitTransactionOptions } from "../interfaces/encompass";
import { Transaction } from "./transaction";
import { IBorrower, ITaxYear } from "../interfaces/borrower";

export class ResubmitTransaction extends Transaction<ResubmitTransactionOptions> {
    constructor(
        requestId: string,
        taxYear: ITaxYear,
        borrower: IBorrower,
        origin: APIOrigin,
    ) {
        super(
            borrower.encompassBorrowerId,
            {
                requestId,
                loanNumber: origin.origin.loan.loanNumber,
                tin: borrower.tin,
                taxYears: [taxYear],
                firstName: borrower.firstName,
                lastName: borrower.lastName,
                loanProfessional: origin.origin.originatingParty.pointOfContact,
                status: "Request Resubmitted",
                requestType: "Request Resubmitted",
                instanceId: origin.instanceId,
            },
        );
    }

    public toTransaction(): TransactionRequest<ResubmitTransactionOptions> {
        return super.toTransaction();
    }
}
