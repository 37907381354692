import { ITaxYear } from "./borrower";
import { ActionReason, OriginResponseV2 } from "./originv2";

export interface IEncompassResource {
    name: string;
    id: string;
    mimeType: string;
    source: string;
}

export interface ProxyFile {
    fileName?: string;
    docType?: number;
    id?: string;
    mimeType?: string;
    name: string;
}

export const fileTypes: {
    [fileNumber: number]: string;
} = {
    7: 'transcriptAuthorization',
    2: 'governmentIssuedPhotoId',
    3: 'selfTakenPicture',
    8: 'businessTranscriptAuthorization',
    5: 'businessGovernmentIssuedPhotoId',
    6: 'businessSelfTakenPicture',
};

export const fileNameTypes: {
    [fileNumber: number]: string;
} = {
    7: 'transcriptAuthorizationPdfFilename',
    2: 'governmentPhotoIdFilename',
    3: 'selfTakenPictureFilename',
    8: 'transcriptAuthorizationPdfFilename',
    5: 'governmentPhotoIdFilename',
    6: 'selfTakenPictureFilename',
};

export const fileTypesReversed: {
    transcriptAuthorization: number;
    governmentIssuedPhotoId: number;
    selfTakenPicture: number;
    businessTranscriptAuthorization: number;
    businessGovernmentIssuedPhotoId: number;
    businessSelfTakenPicture: number;
} = {
    transcriptAuthorization: 7,
    governmentIssuedPhotoId: 2,
    selfTakenPicture: 3,
    businessTranscriptAuthorization: 8,
    businessGovernmentIssuedPhotoId: 5,
    businessSelfTakenPicture: 6,
};

export interface PointOfContact {
    name: string;
    email: string;
    phone: string;
    personas: string[];
}

export interface ProxyIndividual {
    irsIncomeId?: string;
    tin: string;
    firstName: string;
    lastName: string;
    email?: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    taxYears: ITaxYear[];
    files: ProxyFile[];
}

export interface ProxyBusiness {
    irsIncomeId?: string;
    businessName: string;
    ein: string;
    email?: string;
    isSamePerson?: boolean;
    taxYears: ITaxYear[];
    files: ProxyFile[];
}

interface IContact {
    contactType: string;
    email: string;
    name: string;
}

export interface CreateOptions {
    encompassBorrowerId: string;
    loanNumber: string;
    individual: ProxyIndividual[];
    business: ProxyBusiness[];
    loanProfessional: PointOfContact;
    contacts?: string | IContact[];
    channel?: string;
    hasIRSAccount: boolean;
    hasPOA: boolean;
    type: "IRS Wage and Income";
    requestType: "Request Created";
    primaryBorrower: string;
    instanceId: string;
}

export interface AddedOptions {
    encompassBorrowerId: string;
    loanNumber: string;
    business: ProxyBusiness[];
    individualAdditions: ProxyIndividual[];
    businessAdditions: ProxyBusiness[];
    loanProfessional: PointOfContact;
    contacts?: string | IContact[];
    channel?: string;
    type: "IRS Wage and Income";
    requestType: "Request Append";
    primaryBorrower: string;
    instanceId: string;
}

export interface RIAOptions {
    firstName: string;
    lastName: string;
    email: string;
    irsIncomeId?: string;
    loanProfessional: PointOfContact;
    contacts: string[] | string,
    channel: string,
    status: "Request Submitted";
    requestType: "RIA Request Submitted";
    instanceId: string;
    encompassBorrowerId: string;
    primaryBorrower: string;
    loanNumber: string;
    isResend: boolean;
}

// eslint-disable-next-line no-shadow
export enum RIAStatus {
    UNSENT = 'UNSENT',
    SENT = 'SENT',
    ACCEPTED = 'ACCEPTED',
}

export interface IEncompassBorrowerChild {
    requestId: string;
    parentId: string;
    formName: string;
    taxYear: string;
    name?: string;
    orderedOn?: string;
    deliveredOn?: string;
    status: string;
}

export interface IEncompassBorrower {
    id: string;
    rowKey?: string;
    name: string;
    riaStatus: RIAStatus;
    riaInvitationSent?: Date;
    riaInvitationAccepted?: Date;
    children: IEncompassBorrowerChild[];
    actionNeededIssues: string[];
}

export interface TransactionOrigin {
    partnerAccessToken: string;
    id: string;
    context: string;
}

export interface ApplicationBorrower {
    firstName: string
    residences: {
        addressUnitDesignatorType: string;
        addressPostalCode: string;
        addressCity: string;
        addressUnitIdentifier: string;
        residencyType: string;
        addressState: string;
        urla2020StreetAddress: string;
    }[];
    lastName: string;
    emailAddressText: string;
    homePhoneNumber: string;
    fullNameWithSuffix: string;
    taxIdentificationIdentifier: string;
    middleName: string;
    id: string;
}

export interface Application {
    borrower: ApplicationBorrower;
    coborrower: ApplicationBorrower;
    id: string
}

export interface OriginResponse {
    id: string;
    entityRef: {
        entityId: string;
        entityType: string;
    }
    loanFormat: string;
    loan: {
        applications: Application[];
        id: string
        contacts?: string;
        channel: string;
        loanNumber: string
    }
    interfaceUrl: string
    product: {
        id: string
        partnerId: string
        name: string
    }
    environment: string
    originatingParty: {
        name: string
        address: string
        city: string
        state: string
        postalCode: string
        pointOfContact: PointOfContact;
    }
    identity: { hash: string };
}

export interface IExistingRequest {
    orderDate: string;
    deliveryDate: string;
    borrowerName: string;
    status: string;
    providerStatus: string;
    forms: string;
    year: string;
    irsIncomeId: string;
    requestId: string;
    hasIRS: string;
    firstName: string;
    lastName: string;
    tin: string;
    email: string;
    addressLine1: string;
    addressLine2: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    governmentPhotoIdFilename: string;
    selfTakenPictureFilename: string;
    signedConsentPdfFilename: string;
    signedAuthorizationPdfFilename: string;
    transcriptAuthorizationPdfFilename: string;
    businessInformation: {
        businessName: string;
        businessAddressLine1: string;
        businessAddressLine2: string;
        street: string;
        city: string;
        state: string;
        postalCode: string;
        email: string;
        ein: string;
        businessSignedAuthorizationPdf: string;
        businessSignedAuthorizationPdfFilename: string;
        businessGovernmentPhotoId: string;
        businessGovernmentPhotoIdFilename: string;
        businessSelfTakenPicture: string;
        businessSelfTakenPictureFilename: string;
        sendBusinessEntityEmailToGetDocument: boolean;
        isSamePerson: boolean;
        businessTranscriptAuthorizationPdf: string;
        businessTranscriptAuthorizationPdfFilename: string;
    };
    isBorrowerDetailsEdited: boolean;
    encompassBorrowerId: string;
    riaInvitationSent: string;
    riaInvitationAccepted: string;
    riaInvitationStatus: string;
    taxPayerType?: 'Individual' | 'Business';
    actionNeededIssues?: string[] | null;
}

export interface APIOrigin {
    origin: OriginResponse;
    existingRequests: IExistingRequest[];
    hasCreds: boolean;
    invalidCredentials: boolean;
    instanceId: string;
    isShowCopyToEfolder: boolean;
    isDefaultNo: boolean;
    isShowRIA: boolean;
    vendorIDCollection: boolean;
}

export interface APIOriginFromBackend {
    origin: string;
    existingRequests: IExistingRequest[];
    hasCreds: boolean;
    invalidCredentials: boolean;
    instanceId: string;
    isShowCopyToEfolder: boolean;
    isDefaultNo: boolean;
    isShowRIA: boolean;
    vendorIDCollection: boolean;
    originResponse: OriginResponseV2[];
    actionReasons: ActionReason[];
    loanNumber: string;
    primaryBorrowerId: string;
}

export interface EFolderOptions {
    requestType: "resubmit request",
    borrowerId: string;
    loanId: "";
    instanceId: string;
    loanNumber: string;
    firstName: string;
    lastName: string;
    loanProfessional: PointOfContact;
    contacts: string | string[];
    channel: string;
}

export interface CancelTransactionOptions {
    requestId: string;
    irsIncomeId: string;
    loanProfessional: PointOfContact;
    contacts?: string | string[];
    channel?: string;
    status: "Request Submitted";
    requestType: "cancel request";
    instanceId: string;
    encompassBorrowerId: string;
    primaryBorrower: string;
    loanNumber: string;
}

export interface ResubmitTransactionOptions {
    requestId: string;
    loanNumber: string;
    tin: string;
    taxYears: ITaxYear[];
    firstName?: string;
    lastName?: string;
    loanProfessional: PointOfContact;
    contacts?: string | string[];
    channel?: string;
    status: "Request Resubmitted";
    requestType: "Request Resubmitted";
    instanceId: string;
}

export interface CheckAuthTransactionOptions {
    loanNumber: string;
    irsIncomeId: string;
    instanceId: string;
    type: "Request Authorization Check";
    requestType: "Check Authorization";
}

export interface UpdateTransactionOptions {
    loanNumber: string;
    individual: ProxyIndividual[];
    business: ProxyBusiness[];
    loanProfessional: PointOfContact;
    hasIRSAccount: boolean;
    hasPOA: boolean;
    type: "IRS Wage and Income";
    requestType: "Request Updated";
    instanceId: string;
}

export interface OriginError {
    code: string
    description: string;
    resourceId: string;
    type: string;
}

export interface ResendAuthorizationOptions {
    irsIncomeId?: string;
    loanNumber: string;
    tin: string;
    firstName?: string;
    lastName?: string;
    loanProfessional: PointOfContact;
    contacts?: string | string[];
    status: "Resend Authorization";
    requestType: "Resend Authorization";
    instanceId: string;
}
