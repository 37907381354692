import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { APIOrigin, EFolderOptions } from "../interfaces/encompass";
import { Transaction } from "./transaction";

export class EFolderTransaction extends Transaction<EFolderOptions> {
    constructor(origin: APIOrigin) {
        const { loan } = origin.origin;
        const { borrower } = loan.applications[0];
        super(
            borrower.id,
            {
                requestType: "resubmit request",
                borrowerId: borrower.id,
                loanId: "",
                instanceId: origin.instanceId,
                loanNumber: loan?.loanNumber,
                firstName: borrower?.firstName,
                lastName: borrower?.lastName,
                loanProfessional: origin.origin?.originatingParty?.pointOfContact, // we need to map logged in user with employeeId
                contacts: loan?.contacts || '',
                channel: loan?.channel,
            },
        );
    }

    public toTransaction(): TransactionRequest<EFolderOptions> {
        return super.toTransaction();
    }
}
