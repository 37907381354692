/* eslint-disable no-case-declarations */
import { QuestionCircleOutlined } from "@ant-design/icons";
import { CSSProperties, MouseEvent } from "react";
import Dashboard from "./svg/Dashboard";

interface IconProps {
    name: string;
    style?: CSSProperties;
    // theme?: 'filled' | 'outlined' | 'twoTone'
    spin?: boolean;
    rotate?: number;
    twoToneColor?: string;
    size?: string | number;
    color?: string;
    backgroundColor?: string;
    borderRadius?: string | number;
    padding?: number | string;
    badge?: number;
    onClick?: (event?: MouseEvent<any>) => any;
    className?: string
    svg?: 'Dashboard'
}

const Icon = ({
    name,
    style = {},
    size = undefined,
    // theme = 'outlined',
    color = undefined,
    backgroundColor = undefined,
    spin = false,
    rotate = undefined,
    twoToneColor = undefined,
    borderRadius = '100%',
    padding = undefined,
    badge = 0,
    onClick = undefined,
    className = "",
    svg,
}: IconProps) => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const icons = require(`@ant-design/icons`);
    const Component = icons?.[name];

    switch (svg) {
        case 'Dashboard':
            return <Dashboard style={style} />;
        default:
            if (!Component) {
                return (
                    <QuestionCircleOutlined
                        style={{
                            ...style,
                            fontSize: size,
                            color,
                            backgroundColor,
                            borderRadius,
                            padding,
                        }}
                        spin={spin}
                        rotate={rotate}
                        twoToneColor={twoToneColor}
                    />
                );
            }
            const comp = (
                <Component
                    style={{
                        ...style,
                        fontSize: size,
                        color,
                        backgroundColor,
                        borderRadius,
                        padding,
                        cursor: onClick ? 'pointer' : undefined,
                    }}
                    className={badge > 0 ? "" : className}
                    spin={spin}
                    rotate={rotate}
                    twoToneColor={twoToneColor}
                    onClick={onClick}
                />
            );
            if (badge > 0) {
                return (
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            height: size,
                            cursor: onClick ? 'pointer' : undefined,
                        }}
                        className={className}
                        onClick={onClick}
                    >
                        {
                            badge > 0 && (
                                <div
                                    className="danger br-full flex-center-center"
                                    style={{
                                        height: 20,
                                        width: 20,
                                        position: 'absolute',
                                        top: -4,
                                        right: -4,
                                    }}
                                >
                                    <h5 className="white--text">
                                        {badge > 9 ? '9+' : badge}
                                    </h5>
                                </div>
                            )
                        }
                        {comp}
                    </div>
                );
            }
            return (comp);
    }
};

export default Icon;
