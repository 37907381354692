import reactHtmlParser from 'react-html-parser';
import { Alert, Col, Row } from "antd";
import { ActionReason } from "../../utils/interfaces/originv2";

interface ActionNeededReasonParams {
    reason: ActionReason;
}

const ActionNeededReason = ({
    reason,
}: ActionNeededReasonParams) => (
    <div id={reason.actionCode} className="mb-2 issue">
        <Row className="lightBlue white--text pa-2">
            <Col span={2}>Issue</Col>
            <Col offset={1}>{reason.scenario}</Col>
        </Row>
        <Row className="green white--text pa-2">
            <Col span={2}>Action</Col>
            <Col offset={1}>{reason.message}</Col>
        </Row>
        <Row className="pa-2">
            <Col offset={1}>
                {reactHtmlParser(reason.exposition)}
            </Col>
        </Row>
        {reason.tips?.length && (
            <Row className="pa-2" style={{ justifyContent: 'center' }}>
                <Col offset={1}>
                    <Alert
                        message="Useful Tips"
                        type="info"
                        showIcon
                        description={reactHtmlParser(reason.tips)}
                    />
                </Col>
            </Row>
        )}
    </div>
);

export default ActionNeededReason;
