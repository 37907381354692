import { Avatar as AntAvatar } from "antd";
import ColorHash from "color-hash";
import { useMemo } from "react";

interface AvatarParams {
    first?: string;
    sur?: string;
    imageUri?: string;
    icon?: JSX.Element;
    onClick?: () => void;
    size?: 'default' | 'large' | 'small';
}

const Avatar = ({
    first,
    sur,
    imageUri,
    icon,
    onClick,
    size = 'default',
}: AvatarParams) => {
    const hashed: string = useMemo(() => new ColorHash({
        lightness: 0.5,
        hue: { min: 90, max: 330 },
    }).hex((first?.charAt(0) ?? '') + (sur?.charAt(0) ?? '')), [first, sur]);

    return (
        <AntAvatar
            style={{ textTransform: 'uppercase', backgroundColor: hashed }}
            className="mr-1"
            src={imageUri}
            icon={icon}
            onClick={onClick}
            size={size}
        >
            {!(imageUri || icon) && `${first?.charAt(0)}${sur?.charAt(0) || ''}`}
        </AntAvatar>
    );
};

export default Avatar;
