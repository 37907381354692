/* eslint-disable default-param-last */
// eslint-disable-next-line import/no-cycle
import { GET_ORIGIN_SUCCESS } from "./encompass";
import { APIOriginFromBackend } from "../../utils/interfaces/encompass";
import { ActionReason } from "../../utils/interfaces/originv2";
import { IColors, defaultColors } from "../../utils/interfaces/configuration";
import { GET_ME_SUCCESS } from "./auth";
import { Employee } from "../../utils/interfaces/auth";
import execute from "../http/execute";
import { AppDispatch } from "../../configureStore";
import success from "../http/success";
import failure from "../http/failure";
import { get } from "../http/request";

const initialState: {
    institutionName?: string;
    actionReasons: {
        [actionCode: string]: ActionReason;
    };
    colors: IColors;
    institutionId: string | null;
    metadata: {
        formTypes: string[]
    }
} = {
    actionReasons: {},
    colors: defaultColors,
    institutionId: null,
    metadata: {
        formTypes: [],
    },
};

export const INIT_STORAGE = 'configuration/INIT_STORAGE';

export const SET_INSTITUTION_ID = 'configuration/SET_INSTITUTION_ID';

export const GET_METADATA_REQUEST = 'configuration/GET_METADATA_REQUEST';
export const GET_METADATA_SUCCESS = 'configuration/GET_METADATA_SUCCESS';
export const GET_METADATA_FAILURE = 'configuration/GET_METADATA_FAILURE';

type ActionParams =
    | { type: typeof INIT_STORAGE; }
    | { type: typeof GET_ORIGIN_SUCCESS; data: APIOriginFromBackend; }
    | { type: typeof GET_ME_SUCCESS; data: Employee; }
    | { type: typeof GET_METADATA_SUCCESS; data: { responseData: [string[], { name: string; abbreviation: string; }[], string[], number[]]; }; }
    | { type: typeof SET_INSTITUTION_ID; id: string; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_METADATA_SUCCESS:
            return {
                ...state,
                metadata: {
                    formTypes: action.data.responseData[0],
                },
            };

        case SET_INSTITUTION_ID:
            return {
                ...state,
                institutionId: action.id,
            };

        case INIT_STORAGE:
            return {
                ...state,
                institutionId: localStorage.getItem('InstitutionId'),
            };

        case GET_ORIGIN_SUCCESS:
            return {
                ...state,
                institutionName: JSON.parse(action?.data?.origin ?? {})?.originatingParty?.name,
                actionReasons: action.data.actionReasons.reduce((acc, curr) => ({ ...acc, [curr.actionCode]: curr }), state.actionReasons),
            };

        case GET_ME_SUCCESS:
            return {
                ...state,
                colors: {
                    primary: action.data.primaryColor || defaultColors.primary,
                    secondary: action.data.secondaryColor || defaultColors.secondary,
                    tertiary: defaultColors.tertiary,
                },
            };

        default:
            return state;
    }
}

export const getMetadata = (payload: any = {}) => (dispatch: AppDispatch) => {
    execute(get, '/metadata/all')
        .then(success(dispatch, GET_METADATA_SUCCESS, payload))
        .catch(failure(dispatch, GET_METADATA_FAILURE, payload));

    dispatch({
        type: GET_METADATA_REQUEST,
        payload,
    });
};

export default reducer;
