import { useState } from "react";
import { Modal, Tooltip, Typography, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../configureStore";
import { IBorrower } from "../../utils/interfaces/borrower";
import Icon from "../atoms/Icon";
import { ResendAuthorizationTransaction } from "../../utils/encompass/resendAuthorizationTransaction";
import { APIOrigin } from "../../utils/interfaces/encompass";
import { sendEncompassRequest } from "../../redux/reducer/encompass";

interface ResendAccountRequestParams {
    id: string;
}

const ResendAccountRequest = ({
    id,
}: ResendAccountRequestParams) => {
    const borrower = useSelector<RootState, IBorrower>(({ borrower: { borrowers } }) => borrowers[id]);
    const origin = useSelector<RootState, APIOrigin | undefined>(({ encompass: { origin: o } }) => o);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const resend = () => {
        if (borrower && origin) {
            setLoading(true);
            const reorderTxn: ResendAuthorizationTransaction = new ResendAuthorizationTransaction(
                borrower,
                origin,
                borrower.irsIncomeId,
            );
            dispatch(
                sendEncompassRequest({
                    input: reorderTxn,
                    onSuccess: () => {
                        localStorage.setItem("LAST_REFRESH", "RESEND_AUTH");
                        setTimeout(() => { setLoading(false); window.location.reload(); }, 2000);
                    },
                    onError: () => {
                        notification.error({ message: 'Something went wrong!' });
                        setLoading(false);
                    },
                }),
            );
        }
    };

    return (
        <div className="ml-2">
            <Modal
                open={modalOpen}
                onCancel={() => { setModalOpen(false); }}
                okButtonProps={{
                    loading,
                }}
                title="Send Authorization"
                okText="Send Request"
                onOk={() => {
                    resend();
                }}
                styles={{
                    body: {
                        paddingBottom: 8,
                    },
                }}
                width="40%"
            >
                <Typography style={{ marginTop: 16 }}>
                    To resend the authorization request, ensure the borrower has an existing IRS account.
                </Typography>
                <Typography style={{ marginTop: 16 }}>
                    The borrower&apos;s <b>full name</b> and <b>address</b> in the order must match those in the <b>IRS profile</b> of their IRS Account for the request to successfully go through.
                </Typography>
            </Modal>
            <Tooltip
                title="Send Authorization"
                trigger="hover"
                overlayStyle={{ marginRight: 16 }}
            >
                <div style={{ width: 'fit-content' }}>
                    {
                        (borrower.visibleResendAuthorization === true)
                        && (
                            <Icon
                                size={18}
                                onClick={() => {
                                    borrower.enableResendAuthorization === true ? setModalOpen(true) : setModalOpen(false);
                                }}
                                name="SendOutlined"
                            />
                        )
                    }
                </div>
            </Tooltip>
        </div>
    );
};

export default ResendAccountRequest;
