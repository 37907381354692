export interface IPagination {
    currentPage: number;
    nextPage: number;
    perPage: number;
    prevPage: number;
    totalItems: number;
    totalPages: number;
    continuationToken: string | null;
}

export const defaultPagination: IPagination = {
    currentPage: 0,
    nextPage: 0,
    perPage: 100,
    prevPage: 0,
    totalItems: 0,
    totalPages: 0,
    continuationToken: null,
};
