import { Modal, Tooltip, Typography } from "antd";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../configureStore";
import { CheckAuthTransaction } from "../../utils/encompass/checkAuthTransaction";
import { sendEncompassRequest } from "../../redux/reducer/encompass";
import Icon from "../atoms/Icon";
import { IBorrower } from "../../utils/interfaces/borrower";
import { Header } from "../atoms/ModalHeader";

interface CheckAuthorizationParams {
    id: string;
}

const CheckAuthorization = ({
    id,
}: CheckAuthorizationParams) => {
    const instanceId = useSelector<RootState, string>(({ encompass }) => encompass.origin?.instanceId || '');
    const loanNumber = useSelector<RootState, string>(({ encompass }) => encompass.origin?.origin?.loan?.loanNumber || '');
    const borrower = useSelector<RootState, IBorrower>(({ borrower: { borrowers } }) => borrowers[id]);

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [diffMinutes, setDiffMinutes] = useState(0);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const checkTimeDifference = () => {
            if (!borrower.latestCheckRequest) {
                setIsButtonDisabled(false);
            } else {
                const now = DateTime.now();
                const givenDateTime = DateTime.fromJSDate(borrower.latestCheckRequest);
                const differenceInMinutes = now.diff(givenDateTime, 'minutes').minutes;

                setIsButtonDisabled(differenceInMinutes < 2);
            }
            if (borrower.latestAttempt) {
                const now = DateTime.now();
                const givenDateTime = DateTime.fromJSDate(borrower.latestAttempt);
                const differenceInMinutes = now.diff(givenDateTime, 'minutes').minutes;
                setDiffMinutes(Math.floor(differenceInMinutes));
            }
        };

        checkTimeDifference();
        const intervalId = setInterval(checkTimeDifference, 1000);

        return () => clearInterval(intervalId);
    }, [borrower.latestCheckRequest]);

    const createCheckRequest = () => {
        setLoading(true);
        const checkAuthTransaction: CheckAuthTransaction = new CheckAuthTransaction(
            loanNumber,
            borrower.irsIncomeId || '',
            borrower.encompassBorrowerId,
            instanceId,
        );

        dispatch(
            sendEncompassRequest({
                input: checkAuthTransaction,
                onSuccess: () => {
                    localStorage.setItem("LAST_REFRESH", "CHECK_AUTH");
                    setTimeout(() => { window.location.reload(); }, 2500);
                },
            }),
        );
    };

    return (
        <div className="ml-2">
            <Modal
                open={modalOpen}
                onCancel={() => { setModalOpen(false); }}
                title={borrower?.latestAttempt ? <Header title="Check for Transcripts" latestAttempt={borrower?.latestAttempt} /> : "Check for Transcripts"}
                okButtonProps={{
                    loading,
                    disabled: isButtonDisabled,
                }}
                okText="Check"
                onOk={() => {
                    createCheckRequest();
                }}
                styles={{
                    body: {
                        paddingBottom: 8,
                    },
                }}
                width="50%"
            >
                <Typography style={{ marginTop: 16 }}>
                    This will check if the IRS or the Borrower has authorized {borrower?.latestAttempt
                        ? `in the last ${diffMinutes > 1 ? `${diffMinutes} minutes` : 'minute'}.`
                        : 'the request in the last'}
                </Typography>
                {isButtonDisabled && <Typography style={{ marginTop: 16, textAlign: 'end' }}>Please wait for a moment before retrying.</Typography>}
            </Modal>
            <Tooltip
                title="Check for Transcripts"
            >
                <div style={{ width: 'fit-content' }}>
                    <Icon
                        name="FileSearchOutlined"
                        size={18}
                        onClick={() => { setModalOpen(true); }}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default CheckAuthorization;
