/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
/* eslint-disable default-param-last */
import { AppDispatch } from "../../configureStore";
import { IPagination, defaultPagination } from "../../utils/interfaces/pagination";
import { IAppBorrower, ILoan, IRequest, RequestSummary, TPT, initialSummary } from "../../utils/interfaces/request";
import failure from "../http/failure";
import { get } from "../http/request";
import success from "../http/success";
import { SET_INSTITUTION_ID } from "./configuration";

const initialState: {
    summary: [RequestSummary, RequestSummary, RequestSummary]
    requests: IRequest[];
    allLoans: string[];
    loans: {
        [loanNumber: string]: ILoan;
    };
    appBorrowers: {
        [id: string]: IAppBorrower;
    };
    searchLoans: string[]
    paginationInfo?: IPagination;
    detailsModal: boolean;
    activeBorrower?: string;
} = {
    summary: initialSummary,
    requests: [],
    allLoans: [],
    searchLoans: [],
    loans: {},
    appBorrowers: {},
    paginationInfo: defaultPagination,
    detailsModal: false,
};

export const GET_REQUEST_REQUEST = 'request/GET_REQUEST_REQUEST';
export const GET_REQUEST_SUCCESS = 'request/GET_REQUEST_SUCCESS';
export const GET_REQUEST_FAILURE = 'request/GET_REQUEST_FAILURE';

export const GET_REQUEST_CHART_REQUEST = 'request/GET_REQUEST_CHART_REQUEST';
export const GET_REQUEST_CHART_SUCCESS = 'request/GET_REQUEST_CHART_SUCCESS';
export const GET_REQUEST_CHART_FAILURE = 'request/GET_REQUEST_CHART_FAILURE';

export const SEARCH_LOANS_REQUEST = 'request/SEARCH_LOANS_REQUEST';
export const SEARCH_LOANS_SUCCESS = 'request/SEARCH_LOANS_SUCCESS';
export const SEARCH_LOANS_FAILURE = 'request/SEARCH_LOANS_FAILURE';

export const REQUEST_DETAILS_MODAL = 'borrower/REQUEST_DETAILS_MODAL';

type ActionParams =
    | { type: typeof SET_INSTITUTION_ID; }
    | { type: typeof GET_REQUEST_SUCCESS; data: { data: IRequest[]; paginationInfo: IPagination; }; }
    | { type: typeof REQUEST_DETAILS_MODAL; borrowerId?: string; }
    | { type: typeof SEARCH_LOANS_SUCCESS; data: { loanNumber: string; }[]; }
    | { type: typeof GET_REQUEST_CHART_SUCCESS; data: { data: { processing: number; fulfilled: number; needAttention: number; }; }; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_REQUEST_SUCCESS:
            const tempLoans: {
                [loanNumber: string]: ILoan;
            } = Object.keys(state.loans).reduce((acc, curr) => ({
                ...acc,
                [curr]: {
                    ...state.loans[curr],
                    requests: state.loans[curr].requests,
                },
            }), {});

            action.data.data.forEach((req: IRequest) => {
                if (!tempLoans[req.loanNumber]) {
                    tempLoans[req.loanNumber] = {
                        id: req.loanNumber,
                        uiType: 'Loan',
                        loanNum: req.loanNumber,
                        loanNumber: req.loanNumber,
                        children: [],
                        requests: [],
                    };
                }

                if (tempLoans[req.loanNumber].requests.findIndex(({ id }) => id === req.id) === -1) tempLoans[req.loanNumber].requests.push(req);
            });

            const allBrwr: { [irsIncomeId: string]: IAppBorrower } = {};

            Object.keys(tempLoans).forEach((tempLoan) => {
                const brwrObj: { [irsIncomeId: string]: IAppBorrower } = {};
                tempLoans[tempLoan].requests.forEach((req) => {
                    if (!brwrObj[req.irsIncomeId]) {
                        brwrObj[req.irsIncomeId] = {
                            loanNumber: req.loanNumber,
                            id: req.irsIncomeId,
                            uiType: 'Borrower',
                            name: req.name,
                            email: req.email || req.businessEmail,
                            tin: req?.ssn?.replace(/-/g, '') || '',
                            borrowerName: req.name,
                            firstName: req.firstName,
                            lastName: req.lastName,
                            irsIncomeId: req.irsIncomeId,
                            taxPayerType: req.taxPayerType as TPT,
                            actionReasons: req?.actionReasons?.[0],
                            children: [],
                        };
                    }

                    if (brwrObj[req.irsIncomeId].children.findIndex(({ id }) => id === req.id) === -1) brwrObj[req.irsIncomeId].children.push(req);
                    if (!allBrwr[req.irsIncomeId]) {
                        allBrwr[req.irsIncomeId] = brwrObj[req.irsIncomeId];
                    }
                });

                tempLoans[tempLoan].children = Object.values(brwrObj);
            });

            const allLns = [...new Set(action.data.data.map(({ loanNumber }) => loanNumber))];

            return {
                ...state,
                loans: tempLoans,
                appBorrowers: allBrwr,
                allLoans: allLns,
                searchLoans: [...new Set([...state.searchLoans, ...allLns])],
            };

        case SEARCH_LOANS_SUCCESS:
            const allLNs = [...new Set(action.data.map(({ loanNumber }) => loanNumber))];

            return {
                ...state,
                searchLoans: [...new Set([...state.searchLoans, ...allLNs])],
            };

        case SET_INSTITUTION_ID:
            return {
                ...state,
                summary: initialSummary,
                requests: [],
                loans: {},
                paginationInfo: defaultPagination,
            };

        case GET_REQUEST_CHART_SUCCESS:
            return {
                ...state,
                summary: [
                    {
                        type: 'Processing',
                        value: action.data.data.processing,
                    },
                    {
                        type: 'Fulfilled',
                        value: action.data.data.fulfilled,
                    },
                    {
                        type: 'Needs Attention',
                        value: action.data.data.needAttention,
                    },
                ],
            };
        case REQUEST_DETAILS_MODAL:
            return {
                ...state,
                detailsModal: Boolean(action.borrowerId),
                activeBorrower: action.borrowerId,
            };
        default:
            return state;
    }
}

export const getRequests = (
    payload?: {
        page: number;
        perPage: number;
    },
) => (dispatch: AppDispatch) => {
    get('/request', payload)
        .then(success(dispatch, GET_REQUEST_SUCCESS, payload))
        .catch(failure(dispatch, GET_REQUEST_FAILURE, payload));

    dispatch({
        type: GET_REQUEST_REQUEST,
        payload,
    });
};

export const searchLoanNumbers = (
    payload: {
        loanNumber: string;
    },
) => (dispatch: AppDispatch) => {
    get('/customer', { 'q:loanNumber': payload.loanNumber, filters: true, page: 1, perPage: 10 })
        .then(success(dispatch, SEARCH_LOANS_SUCCESS, payload))
        .catch(failure(dispatch, SEARCH_LOANS_FAILURE, payload));

    dispatch({
        type: SEARCH_LOANS_REQUEST,
        payload,
    });
};

export const getRequestSummary = (payload?: { employeeId?: string; filterBy?: string; }) => (dispatch: AppDispatch) => {
    get('/requeststats', { type: 'IRS Wage and Income', ...payload })
        .then(success(dispatch, GET_REQUEST_CHART_SUCCESS, payload))
        .catch(failure(dispatch, GET_REQUEST_CHART_FAILURE, payload));

    dispatch({
        type: GET_REQUEST_CHART_REQUEST,
        payload,
    });
};

export default reducer;
