import { Button } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { sendEncompassRiaRequest } from "../../../redux/reducer/encompass";
import RIAInvitation from "./RIAInvitation";
import { AppDispatch, RootState } from "../../../configureStore";
import { setActiveBorrower } from "../../../redux/reducer/borrower";
import { IRIAForm } from "../../../utils/ria";
import { RiaTransaction } from "../../../utils/encompass/riaTransaction";
import { APIOrigin, RIAStatus } from "../../../utils/interfaces/encompass";
import { IBorrower } from "../../../utils/interfaces/borrower";

interface RIAButtonParams {
    id: string;
    status: RIAStatus;
    riaInvitationSent?: Date;
    riaInvitationAccepted?: Date;
}

const RIAButton = ({
    id,
    status,
    riaInvitationSent,
    riaInvitationAccepted,
}: RIAButtonParams) => {
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const origin = useSelector<RootState, APIOrigin | undefined>(({ encompass }) => encompass?.origin);

    const sendInvitation = (form: IRIAForm, borrower: IBorrower) => {
        if (origin) {
            const riaTxn: RiaTransaction = new RiaTransaction(form, borrower, origin, status === RIAStatus.SENT);
            dispatch(
                sendEncompassRiaRequest({
                    input: riaTxn,
                    onSuccess: () => {
                        setOpen(false);
                        window.location.reload();
                    },
                }),
            );
        }
    };

    return (
        <div>
            <RIAInvitation
                open={open}
                setOpen={setOpen}
                sendInvitation={sendInvitation}
            />
            {
                status === RIAStatus.UNSENT
                    ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpen(true);
                                dispatch(setActiveBorrower(id));
                            }}
                        >
                            Send Invitation
                        </Button>
                    )
                    : status === RIAStatus.SENT
                        ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpen(true);
                                    dispatch(setActiveBorrower(id));
                                }}
                            >
                                Re-Send Invitation
                            </Button>
                        )
                        : <div style={{ display: 'none' }} />
            }
            {
                riaInvitationSent && <p style={{ whiteSpace: "nowrap" }} className="mb-0 mt-1">Invited on: {DateTime.fromJSDate(riaInvitationSent).toFormat('DD')}</p>
            }
            {
                riaInvitationAccepted && <p style={{ whiteSpace: "nowrap" }} className="mb-0 mt-1">Accepted on: {DateTime.fromJSDate(riaInvitationAccepted).toFormat('DD')}</p>
            }
        </div>
    );
};

export default RIAButton;
