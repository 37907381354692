/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
// eslint-disable-next-line import/no-cycle
import { Payload } from ".";
// eslint-disable-next-line import/no-cycle
import {
    GET_ORIGIN_SUCCESS,
} from "./encompass";
import { AppDispatch } from "../../configureStore";
import { IBorrower, ITaxYear } from "../../utils/interfaces/borrower";
import { APIOriginFromBackend } from "../../utils/interfaces/encompass";
import { halfStr } from "../../utils/helpers";
import { ActionReason, OriginResponseV2 } from "../../utils/interfaces/originv2";
import { IAppBorrower } from "../../utils/interfaces/request";

const mapEncompassBorrowerV2 = (origin: OriginResponseV2, loanNumber: string): IBorrower => {
    try {
        const taxYears: ITaxYear[] = [];
        let governmentPhotoIdFilename: string | undefined;
        let selfTakenPictureFilename: string | undefined;
        let transcriptAuthorizationPdfFilename: string | undefined;
        let hasIRS: string = "No";
        let actionReason: ActionReason | undefined;
        let latestAttempt: Date | undefined;
        let hasTIAStatus: boolean = false;

        origin.requests.forEach((request) => {
            if (request.status.startsWith('TIA Sent to')) hasTIAStatus = true;
            hasIRS = request.hasIRS === "Yes" ? "Yes" : "No";
            taxYears.push({
                form: request.formDescription ?? request.formNumber,
                multiYear: request?.taxYear?.startsWith('Year') ? request.taxYear : '',
                year: !request?.taxYear?.startsWith('Year') ? request.taxYear : '',
            });
            actionReason = actionReason ?? request.actionReasons?.[0];
            if (request.latestAttempt) {
                if (!latestAttempt || new Date(request.latestAttempt) > latestAttempt) latestAttempt = new Date(request.latestAttempt);
            }
            if (request.taxPayerType === 'Business') {
                governmentPhotoIdFilename = governmentPhotoIdFilename ?? request?.businessGovernmentPhotoIdFilename ?? request?.businessInformation?.businessGovernmentPhotoIdFilename;
                selfTakenPictureFilename = selfTakenPictureFilename ?? request?.businessSelfTakenPictureFilename ?? request?.businessInformation?.businessSelfTakenPictureFilename;
                transcriptAuthorizationPdfFilename = transcriptAuthorizationPdfFilename ?? request?.businessTranscriptAuthorizationPdfFilename ?? request?.businessInformation?.businessTranscriptAuthorizationPdfFilename;
            } else {
                governmentPhotoIdFilename = governmentPhotoIdFilename ?? request.governmentPhotoIdFilename;
                selfTakenPictureFilename = selfTakenPictureFilename ?? request.selfTakenPictureFilename;
                transcriptAuthorizationPdfFilename = transcriptAuthorizationPdfFilename ?? request.transcriptAuthorizationPdfFilename;
            }
        });

        if (origin.taxPayerType === 'Individual') {
            return {
                taxPayerType: 'Individual',
                id: origin.irsIncomeId || origin.encompassId,
                requestId: origin.irsIncomeId || origin.encompassId, // For UI purposes only
                irsIncomeId: origin.irsIncomeId,
                encompassBorrowerId: origin.encompassId,
                firstName: origin.firstName,
                lastName: origin.lastName,
                name: origin.fullNameWithSuffix,
                email: origin.emailAddressText,
                loanNumber,
                addressLineOne: origin.urla2020StreetAddress,
                addressLineTwo: origin.addressUnit,
                city: origin.addressCity,
                postalCode: origin.addressPostalCode,
                state: origin.addressState,
                tin: origin.taxIdentificationIdentifier,
                hasIRS,
                governmentPhotoIdFilename,
                selfTakenPictureFilename,
                transcriptAuthorizationPdfFilename,
                hasRequest: origin.requests.length > 0,
                taxYears,
                actionReason,
                requests: origin.requests,
                history: origin.history,
                latestAttempt,
                visibleResendAuthorization: origin.visibleResendAuthorization,
                enableResendAuthorization: origin.enableResendAuthorization,
                latestCheckRequest: origin.latestCheckRequest ? new Date(origin.latestCheckRequest) : undefined,
                hasTIAStatus,
            };
        }

        const biz: IBorrower = {
            taxPayerType: 'Business',
            id: origin.irsIncomeId,
            requestId: origin.irsIncomeId, // For UI purposes only
            irsIncomeId: origin.irsIncomeId,
            encompassBorrowerId: origin.encompassId,
            email: origin.emailAddressText,
            tin: origin.taxIdentificationIdentifier,
            governmentPhotoIdFilename,
            selfTakenPictureFilename,
            transcriptAuthorizationPdfFilename,
            hasRequest: origin.requests.length > 0,
            taxYears,
            actionReason,
            hasIRS: "No",
            name: halfStr(origin.fullNameWithSuffix) || "",
            loanNumber,
            requests: origin.requests,
            history: origin.history,
            latestAttempt,
            visibleResendAuthorization: false,
            enableResendAuthorization: false,
            latestCheckRequest: origin.latestCheckRequest ? new Date(origin.latestCheckRequest) : undefined,
            hasTIAStatus,
        };

        return biz;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

const initialState: {
    activeBorrower?: string;
    borrower?: IAppBorrower;
    borrowers: {
        [id: string]: IBorrower;
    }
    isEdit: boolean;
    isAdd: boolean;
    requestId?: string;
    lockBorrower: boolean;
    isFix: boolean;
    historyOpen: boolean;
} = {
    borrowers: {},
    isEdit: false,
    isAdd: false,
    lockBorrower: false,
    isFix: false,
    historyOpen: false,
};

export const GET_BORROWERS_REQUEST = 'borrower/GET_BORROWERS_REQUEST';
export const GET_BORROWERS_SUCCESS = 'borrower/GET_BORROWERS_SUCCESS';
export const GET_BORROWERS_FAILURE = 'borrower/GET_BORROWERS_FAILURE';

export const SET_ACTIVE_BORROWER = 'borrower/SET_ACTIVE_BORROWER';
export const CLEAR_ACTIVE_BORROWER = 'borrower/CLEAR_ACTIVE_BORROWER';

export const SET_LOCK_BORROWER = 'borrower/SET_LOCK_BORROWER';
export const CLEAR_LOCK_BORROWER = 'borrower/CLEAR_LOCK_BORROWER';

export const SET_IS_EDIT = 'borrower/SET_IS_EDIT';

export const SET_IS_FIX = 'borrower/SET_IS_FIX';

export const SET_IS_ADD = 'borrower/SET_IS_ADD';

export const SET_HISTORY = 'borrower/SET_HISTORY';

export const SET_RESEND_AUTHORIZATION = 'borrower/SET_RESEND_AUTHORIZATION';
export const SET_REQUEST_ID = 'borrower/SET_REQUEST_ID';
export const CLEAR_REQUEST_ID = 'borrower/CLEAR_REQUEST_ID';

type ActionParams =
    | { type: typeof SET_ACTIVE_BORROWER; id: string; }
    | { type: typeof SET_REQUEST_ID; id: string; }
    | { type: typeof SET_IS_EDIT; isEdit: boolean; }
    | { type: typeof SET_IS_ADD; isAdd: boolean; }
    | { type: typeof SET_HISTORY; isOpen: boolean; }
    | { type: typeof GET_BORROWERS_SUCCESS; data: IBorrower[]; }
    | { type: typeof CLEAR_ACTIVE_BORROWER; }
    | { type: typeof SET_LOCK_BORROWER; }
    | { type: typeof CLEAR_LOCK_BORROWER; }
    | { type: typeof CLEAR_REQUEST_ID; }
    | { type: typeof SET_IS_FIX; isFix: boolean; }
    | { type: typeof GET_ORIGIN_SUCCESS; payload: Payload; data: APIOriginFromBackend; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case SET_HISTORY:
            return {
                ...state,
                historyOpen: action.isOpen,
            };

        case GET_ORIGIN_SUCCESS:
            const brwrs = action.data.originResponse.map((brwr) => mapEncompassBorrowerV2(brwr, action.data.loanNumber));

            return {
                ...state,
                borrowers: brwrs.reduce((acc, curr) => ({
                    ...acc,
                    [curr.id]: curr,
                }), state.borrowers),
            };
        case GET_BORROWERS_SUCCESS:
            return {
                ...state,
                borrowers: action.data.reduce((acc, curr) => ({
                    ...acc,
                    [curr.id]: curr,
                }), state.borrowers),
            };
        case SET_ACTIVE_BORROWER:
            return {
                ...state,
                activeBorrower: action.id,
            };
        case CLEAR_ACTIVE_BORROWER:
            return {
                ...state,
                activeBorrower: undefined,
            };
        case SET_LOCK_BORROWER:
            return {
                ...state,
                lockBorrower: true,
            };
        case CLEAR_LOCK_BORROWER:
            return {
                ...state,
                lockBorrower: false,
            };
        case SET_REQUEST_ID:
            return {
                ...state,
                requestId: action.id,
            };
        case CLEAR_REQUEST_ID:
            return {
                ...state,
                requestId: undefined,
            };
        case SET_IS_EDIT:
            return {
                ...state,
                isEdit: action.isEdit,
            };
        case SET_IS_ADD:
            return {
                ...state,
                isAdd: action.isAdd,
            };
        case SET_IS_FIX:
            return {
                ...state,
                isFix: action.isFix,
            };
        default:
            return state;
    }
}

export const setActiveBorrower = (id: string) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_ACTIVE_BORROWER,
        id,
    });
};

export const setLockBorrower = () => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_LOCK_BORROWER,
    });
};

export const clearLockBorrower = () => (dispatch: AppDispatch) => {
    dispatch({
        type: CLEAR_LOCK_BORROWER,
    });
};

export const setRequestId = (id: string) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_REQUEST_ID,
        id,
    });
};

export const setIsEdit = (isEdit: boolean) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_IS_EDIT,
        isEdit,
    });
};

export const setIsFix = (isFix: boolean) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_IS_FIX,
        isFix,
    });
};

export const setIsAdd = (isAdd: boolean) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_IS_ADD,
        isAdd,
    });
};

export const setCustomerHistory = (isOpen: boolean) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_HISTORY,
        isOpen,
    });
};

export const setResendAuthorization = (isOpen: boolean) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_RESEND_AUTHORIZATION,
        isOpen,
    });
};

export default reducer;
