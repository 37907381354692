import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import Typography from "antd/es/typography/Typography";

import { IBorrower } from "../../utils/interfaces/borrower";
import { AppDispatch } from "../../configureStore";
import { setActiveBorrower, setIsEdit, setIsFix } from "../../redux/reducer/borrower";
import Icon from "../atoms/Icon";

interface ActionNeededParams {
    borrower: IBorrower;
    setOpen: (_: boolean) => void;
}

const ActionNeeded = ({
    borrower,
    setOpen,
}: ActionNeededParams) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div className="flex-center-between">
            <Tooltip
                title={borrower?.actionReason?.scenario}
                className="flex-center-between"
            >
                <Typography>
                    Action Needed
                </Typography>
                <Icon
                    name="ExclamationCircleOutlined"
                    className="ml-1"
                    color="red"
                />
            </Tooltip>
            <Typography
                style={{
                    margin: 0,
                    marginRight: 4,
                    textDecoration: 'underline',
                    color: 'blue',
                    fontWeight: 'bold',
                    fontSize: 16,
                    cursor: 'pointer',
                    width: 'fit-content',
                }}
                onClick={() => {
                    dispatch(setIsEdit(true));
                    dispatch(setIsFix(true));
                    dispatch(setActiveBorrower(borrower.id));
                    setOpen(true);
                }}
            >
                Fix Now
            </Typography>
        </div>
    );
};

export default ActionNeeded;
