import { DonutChart } from "bizcharts";
import { useSelector } from "react-redux";

import { RequestSummary } from "../../utils/interfaces/request";
import { RootState } from "../../configureStore";
import { IColors } from "../../utils/interfaces/configuration";

interface RequestChartParams {
    data: [RequestSummary, RequestSummary, RequestSummary]
}

const RequestChart = ({
    data,
}: RequestChartParams) => {
    const colors = useSelector<RootState, IColors>(({ configuration }) => configuration.colors);

    return (
        <DonutChart
            color={[
                colors.primary,
                colors.secondary,
                colors.tertiary,
            ]}
            statistic={{
                title: {
                    customHtml: () => 'Total',
                    style: { fontSize: '16px' },
                },
                content: {
                    style: { fontSize: '16px' },
                },
            }}
            legend={false}
            pieStyle={{ lineWidth: 1 }}
            data={data}
            height={200}
            width={200}
            autoFit
            angleField="value"
            colorField="type"
            innerRadius={0.65}
            radius={0.8}
        />
    );
};

export default RequestChart;
