import { Form } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IndividualTranscript from "../molecules/IndividualTranscript";
import { RootState } from "../../configureStore";
import { IBorrower } from "../../utils/interfaces/borrower";

interface IndividualTranscriptsParams {
    remove: (index: number) => void;
    openFirst?: boolean;
    setOpenFirst?: (_: boolean) => void;
    display?: boolean;
}

const DEFAULT_INDIVIDUAL = {
    firstName: undefined,
    lastName: undefined,
    tin: undefined,
    email: undefined,
    addressLineOne: undefined,
    addressLineTwo: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
    yearList: [0],
    multi: 'Last 2 Years',
};

const IndividualTranscripts = ({
    remove,
    openFirst,
    setOpenFirst,
    display = true,
}: IndividualTranscriptsParams) => {
    const borrower = useSelector<RootState, IBorrower | undefined>(({ borrower: { activeBorrower, borrowers } }) => (activeBorrower ? borrowers[activeBorrower] : undefined));
    const hasRequest = useSelector<RootState, boolean>(({ borrower: { activeBorrower, borrowers } }) => (borrowers[activeBorrower || ""]?.requests?.length > 0 && borrowers[activeBorrower || ""].taxPayerType === "Individual"));
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const [open, setOpen] = useState<Set<number>>((isAdd || isEdit) ? new Set<number>([0]) : new Set<number>());

    useEffect(() => {
        if (openFirst && !open.has(0) && setOpenFirst) {
            const newOpen = new Set([...open]);
            newOpen.add(0);
            setOpen(newOpen);
            setOpenFirst(false);
        }
    }, [openFirst]);

    return !display ? (
        <div style={{ display: 'none' }} />
    ) : (
        <Form.List name="individual">
            {(fields, { add }) => (
                <IndividualTranscript
                    className={(isEdit && !hasRequest) ? 'none' : undefined}
                    open={open}
                    fields={fields}
                    onOpenChange={(field) => {
                        const newOpen = new Set([...open]);
                        if (open.has(field.name)) {
                            newOpen.delete(field.name);
                        } else {
                            newOpen.add(field.name);
                        }
                        setOpen(newOpen);
                    }}
                    change={(action, toRemove) => {
                        const newOpen = new Set([...open]);
                        switch (action) {
                            case "add":
                                if (fields.length === 0 && borrower) {
                                    add({
                                        firstName: borrower?.firstName,
                                        lastName: borrower?.lastName,
                                        tin: borrower?.tin,
                                        email: borrower?.email,
                                        addressLineOne: borrower?.addressLineOne,
                                        addressLineTwo: borrower?.addressLineTwo,
                                        city: borrower?.city,
                                        state: borrower?.state,
                                        postalCode: borrower?.postalCode,
                                        yearList: [0],
                                        multi: 'Last 2 Years',
                                    });
                                } else {
                                    add(DEFAULT_INDIVIDUAL);
                                }
                                newOpen.add(0);

                                break;
                            case "remove":
                                if (toRemove) {
                                    newOpen.delete(toRemove.name);
                                    remove(toRemove.name);
                                }
                                break;
                            default:
                                console.error('What happened?');
                                break;
                        }
                        setOpen(newOpen);
                    }}
                />
            )}
        </Form.List>
    );
};

export default IndividualTranscripts;
