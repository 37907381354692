import { Col, FormListFieldData, Row } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import FormItem from "../atoms/FormItem";
import AddTranscript from "./AddTranscript";
import BusinessInformation from "./BusinessInformation";
import FormTypes from "./FormTypes";
import DocumentUpload from "./DocumentUpload";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";

interface BusinessFormParams {
    open: Set<number>;
    onOpenChange: (field: FormListFieldData) => void;
    change: (action: 'remove' | 'add', toRemove?: FormListFieldData) => void;
    field: FormListFieldData;
    idx: number;
}

const BusinessForm = ({
    open,
    onOpenChange,
    change,
    field,
    idx,
}: BusinessFormParams) => {
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const { isSmall, isMedium } = useWindowDimensions();

    return (
        <div key={`business-txpt-${idx}`}>
            <div className="none"><FormItem label="irsIncomeId" index={field.key} /></div>
            {(!isEdit && !isAdd) && (
                <AddTranscript
                    isBusiness
                    onClick={() => { onOpenChange(field); }}
                    onRemove={(isAdd || isEdit) ? undefined : () => { change('remove', field); }}
                    isOpen={open.has(field.name)}
                    businessName={undefined}
                />
            )}
            <div style={{ display: !open.has(field.name) ? 'none' : undefined }}>
                <BusinessInformation
                    existing={isAdd || isEdit}
                    index={field.key}
                    onNameChange={() => { }}
                />
                <Row gutter={8}>
                    <Col className={isEdit ? 'none' : ''} span={12}>
                        <FormTypes
                            existing={isAdd || isEdit}
                            index={field.key}
                            type="Business"
                        />
                    </Col>
                    <Col
                        className={isAdd ? 'none' : ''}
                        span={12}
                    >
                        <DocumentUpload
                            index={field.key}
                            isBusiness
                            isEdit={isEdit}
                            span={isEdit ? isMedium ? 24 : 10 : isSmall ? 24 : 12}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default BusinessForm;
