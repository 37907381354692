import { Button } from "antd";

import Icon from "../atoms/Icon";

interface NoAccessParams {
    close: () => void;
}

const NoAccess = ({
    close,
}: NoAccessParams) => (
    <div
        style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            minHeight: "100vh",
            color: "#000133",
            flexDirection: 'column',
        }}
    >
        <Button
            htmlType="button"
            type="primary"
            style={{ textTransform: 'none', alignSelf: 'flex-end', position: 'absolute', top: 8, right: 8, margin: 1 }}
            onClick={() => { close(); }}
        >
            Close
        </Button>
        <h3 className="my-1">Activate Halcyon&apos;s Tax Transcript Service!</h3>
        <p className="my-1">It seems you haven&apos;t subscribed to our Tax Transcript service yet. To enjoy the full benefits and activate this service, please contact Halcyon Still Water.</p>
        <div className="my-1 flex-center-center">
            <Icon className="mr-2" name="MailOutlined" color="primary" />
            <h6>Email us at: <a href="mailto:info@halcyonsw.com">info@halcyonsw.com</a></h6>
        </div>
        <p className="my-1">We&apos;re here to assist you!</p>
    </div>
);

export default NoAccess;
