import { useDispatch, useSelector } from "react-redux";
import {
    Col,
    Form,
    Modal,
    Row,
    Select,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
import { AppDispatch, RootState } from "../../configureStore";
import { clearLockBorrower, setActiveBorrower, setIsAdd, setIsEdit, setIsFix } from "../../redux/reducer/borrower";
import { ITranscriptRequest } from "../../utils/interfaces/create";
import { APIOrigin } from "../../utils/interfaces/encompass";
import { IBorrower } from "../../utils/interfaces/borrower";
import OrderTranscripts from "../templates/OrderTranscripts";
import { RequestTransaction } from "../../utils/encompass/requestTransaction";
import { sendEncompassRequest } from "../../redux/reducer/encompass";
import { AddedTransaction } from "../../utils/encompass/addedTransaction";
import { UpdateTransaction } from "../../utils/encompass/updateTransaction";
import ActionReason from "../molecules/ActionReason";

interface EncompassOrderFormParams {
    open: boolean;
    setOpen: (_: boolean) => void;
}

const EncompassOrderForm = ({
    open,
    setOpen,
}: EncompassOrderFormParams) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch<AppDispatch>();
    const handleClose = () => {
        setOpen(false);
        form.resetFields();
        dispatch(setActiveBorrower(''));
        dispatch(setIsEdit(false));
        dispatch(setIsFix(false));
        dispatch(setIsAdd(false));
        dispatch(clearLockBorrower());
    };
    const origin = useSelector<RootState, APIOrigin | undefined>(({ encompass }) => encompass.origin);
    const activeBorrower = useSelector<RootState, string | undefined>(({ borrower }) => borrower.activeBorrower);
    const borrower = useSelector<RootState, IBorrower | undefined>(({ borrower: { activeBorrower: actBrw, borrowers } }) => (actBrw ? borrowers[actBrw] : undefined));
    const primaryBorrower = useSelector<RootState, string>(({ encompass: { primaryBorrowerId: pbi } }) => pbi || '');
    const isEdit = useSelector<RootState, boolean>(({ borrower: brwr }) => brwr.isEdit);
    const lockBorrower = useSelector<RootState, boolean>(({ borrower: brwr }) => brwr.lockBorrower);
    const isFix = useSelector<RootState, boolean>(({ borrower: { isFix: isF } }) => isF);

    const handleSubmit = (values: ITranscriptRequest, isAdd?: boolean) => {
        if (origin && activeBorrower && borrower) {
            if (isEdit) {
                const updateTransaction: UpdateTransaction = new UpdateTransaction(
                    borrower,
                    values,
                    origin,
                    (borrower.hasIRS === "Yes") !== values.hasIRS,
                );

                dispatch(
                    sendEncompassRequest({
                        input: updateTransaction,
                        onSuccess: () => {
                            setTimeout(() => { window.location.reload(); }, 2000);
                        },
                    }),
                );
            } else if (isAdd) {
                const addedTransaction: AddedTransaction = new AddedTransaction(
                    borrower,
                    values,
                    origin,
                );

                dispatch(
                    sendEncompassRequest({
                        input: addedTransaction,
                        onSuccess: () => {
                            setTimeout(() => { window.location.reload(); }, 2000);
                        },
                    }),
                );
            } else {
                const requestTransaction: RequestTransaction = new RequestTransaction(
                    borrower.encompassBorrowerId,
                    values,
                    origin,
                );

                dispatch(
                    sendEncompassRequest({
                        input: requestTransaction,
                        onSuccess: () => {
                            setTimeout(() => { window.location.reload(); }, 2000);
                        },
                    }),
                );
            }
        } else if (origin && values.individual.length === 0 && values.business.length) {
            const requestTransaction: RequestTransaction = new RequestTransaction(
                primaryBorrower,
                values,
                origin,
            );

            dispatch(
                sendEncompassRequest({
                    input: requestTransaction,
                    onSuccess: () => {
                        setTimeout(() => { window.location.reload(); }, 2000);
                    },
                }),
            );
        }
    };

    const options = useSelector<RootState, DefaultOptionType[]>(({ borrower: { borrowers } }) => Object.keys(borrowers).map((id) => ({ label: borrowers[id].name, value: id })));

    return (
        <Modal
            className="hsw-form"
            open={open}
            destroyOnClose
            afterClose={handleClose}
            closable={false}
            footer={null}
            width="80%"
            title={isEdit ? `Edit Order${(borrower?.actionReason && isFix) ? ` - ${borrower?.actionReason.scenario}` : ''}` : "Order Tax Transcript"}
        >
            {(borrower?.actionReason && isFix) && (
                <ActionReason />
            )}
            {
                (!isEdit && !lockBorrower) && (
                    <>
                        <h5>
                            Borrower/Coborrower
                        </h5>
                        <Row>
                            <Col span={12}>
                                <Select
                                    options={options}
                                    placeholder="Select a borrower"
                                    style={{ width: '100%' }}
                                    onChange={(value) => { dispatch(setActiveBorrower(value)); }}
                                />
                            </Col>
                        </Row>
                    </>
                )
            }
            <OrderTranscripts
                form={form}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
            />
        </Modal>
    );
};

export default EncompassOrderForm;
