import { useEffect, useState } from 'react';
import './App.css';
import HostConnection from './utils/em-host';
import Encompass from './apps/Encompass';
import WebApp from './apps/WebApp';
import sandbox from './sandbox';

function App() {
  const [isEncompass, setIsEncompass] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    async function load() {
      if (window.top === window.self) {
        sandbox.set('encompass', false);
        setIsEncompass(false);
        setLoaded(true);
      } else if (window.location?.ancestorOrigins?.contains('https://www.encompassloconnect.com') || window.location?.ancestorOrigins?.contains('https://prod-ha-2.ssf.epc.ellieservices.com') || window.location?.ancestorOrigins?.contains('https://epc-ha.encompass.ice.com')) {
        sandbox.set('encompass', true);
        setIsEncompass(true);
        setLoaded(true);
      } else {
        try {
          sandbox.set('encompass', true);
          await HostConnection();
          setIsEncompass(true);
          setLoaded(true);
        } catch (err) {
          sandbox.set('encompass', false);
          setIsEncompass(false);
          setLoaded(true);
        }
      }
    }

    load().catch(console.error);
  }, []);

  return !loaded ? <div /> : isEncompass ? <Encompass /> : <WebApp />;
}

export default App;
