import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { ConfigProvider } from "antd";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import { jwtInterceptor } from "../jwtInterceptor";
import store, { AppDispatch, RootState } from "../configureStore";
import { msalConfig } from "../authConfig";
import { getInstitutions, getMe } from "../redux/reducer/auth";
import WebAppTemplate from "../components/templates/WebAppTemplate";
import { noAuth } from "../utils";
import LoadingOverlay from "../components/atoms/LoadingOverlay";
import Dashboard from "../components/templates/Dashboard";
import { IColors } from "../utils/interfaces/configuration";
import { INIT_STORAGE, getMetadata } from "../redux/reducer/configuration";
import SettingsTemplate from "../components/templates/SettingsTemplate";

const Pages = () => {
    const dispatch = useDispatch<AppDispatch>();
    const me = useSelector<RootState, any>(({ auth }) => auth.me as any);
    const meFailed = useSelector<RootState, boolean>(({ status }) => status.failure['auth/GET_ME'] && !status.success['auth/GET_ME']);

    useEffect(() => {
        if (!me && !meFailed) {
            dispatch(getMe({
                onSuccess: ({ data }) => {
                    if (data.role === 'Halcyon Operations Manager') { dispatch(getInstitutions()); }
                    dispatch(getMetadata());
                },
            }));
        }
    }, [dispatch, meFailed]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<WebAppTemplate />}>
                    <Route index element={<Dashboard />} />
                    <Route path="institutions" element={<div>Institutions</div>} />
                    <Route path="requests" element={<div>Requests</div>} />
                    <Route path="settings" element={<SettingsTemplate />} />
                    <Route path="notifications" element={<div>Notifications</div>} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

const NoAuthPages = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<div>Unauthenticated</div>} />
        </Routes>
    </BrowserRouter>
);

interface WebAppAppParams {
}

const WebAppApp = ({
}: WebAppAppParams) => {
    const dispatch = useDispatch<AppDispatch>();
    dispatch({ type: INIT_STORAGE });

    const request = {
        scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE ?? 'e3ab3c68-323a-4c5c-8268-f92dc493e3c8'}/api`],
    };
    const { result } = noAuth.includes(window.location.pathname) ? { result: null } : useMsalAuthentication(InteractionType.Redirect, request);
    const error = useSelector<RootState, string | undefined>(({ status }) => status.error);
    const successful = useSelector<RootState, string | undefined>(({ status }) => status.successful);
    const colors = useSelector<RootState, IColors>(({ configuration }) => configuration.colors);
    const [_open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        window.document.documentElement.style.setProperty('--text-color', colors.secondary);
        window.document.documentElement.style.setProperty('var(--ant-primary-color)', colors.primary);
    }, [colors]);

    useEffect(() => {
        if (error || successful) setOpen(true);
    }, [error, successful]);

    useEffect(() => {
        if (result) {
            const { accessToken, idToken } = result;
            localStorage.setItem('ACCESS_TOKEN', accessToken);
            localStorage.setItem('ID_TOKEN', idToken);
            try {
                localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
            } catch (err) {
                console.error(err);
            }
        }
    }, [dispatch, result]);

    return (
        <ConfigProvider theme={{ token: { colorPrimary: colors.primary } }}>
            <AuthenticatedTemplate>
                <Pages />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuthPages />
            </UnauthenticatedTemplate>
        </ConfigProvider>
    );
};

const WebApp = () => {
    if (process.env.NODE_ENV !== 'development') window.location.replace('https://www.halcyonsw.com');
    const [loaded, setLoaded] = useState<boolean>(false);

    if (process.env.NODE_ENV !== 'development') return <div />;
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.initialize().then(() => {
        jwtInterceptor(msalInstance);
        setLoaded(true);
    }).catch((err) => {
        console.error(err);
    });

    return !loaded ? (
        <LoadingOverlay />
    ) : (
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <WebAppApp />
            </Provider>
        </MsalProvider>
    );
};

export default WebApp;
