import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../configureStore";
import { CLEAR_ACTIVE_BORROWER, CLEAR_REQUEST_ID } from "../../redux/reducer/borrower";
import YesNoButtons from "../molecules/YesNoButtons";
import { CancelTransaction } from "../../utils/encompass/cancelTransaction";
import { IBorrower } from "../../utils/interfaces/borrower";
import { APIOrigin } from "../../utils/interfaces/encompass";
import { sendEncompassRequest } from "../../redux/reducer/encompass";

interface CancelRequestParams {
    open: boolean;
    setOpen: (_: boolean) => void;
}

const CancelRequest = ({
    open,
    setOpen,
}: CancelRequestParams) => {
    const requestId = useSelector<RootState, string>(({ borrower: { requestId: rId } }) => rId || '');
    const borrower = useSelector<RootState, IBorrower | undefined>(({ borrower: { borrowers, activeBorrower } }) => borrowers[activeBorrower || '']);
    const origin = useSelector<RootState, APIOrigin | undefined>(({ encompass }) => encompass.origin);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['encompass/CREATE_TRANSACTION']);

    const dispatch = useDispatch<AppDispatch>();
    const handleClose = () => {
        dispatch({ type: CLEAR_ACTIVE_BORROWER });
        dispatch({ type: CLEAR_REQUEST_ID });
        setOpen(false);
    };

    const handleYes = () => {
        if (origin && borrower) {
            const cancelTxn: CancelTransaction = new CancelTransaction(requestId, borrower, origin);
            dispatch(
                sendEncompassRequest({
                    input: cancelTxn,
                    onSuccess: () => {
                        handleClose();
                        window.location.reload();
                    },
                    onError: (err) => {
                        console.error(err);
                        handleClose();
                        window.location.reload();
                    },
                }),
            );
        }
    };

    return (
        <Modal
            title="Cancel Request"
            maskClosable={false}
            onCancel={handleClose}
            open={open}
            footer={<YesNoButtons loading={loading} handleNo={handleClose} handleYes={handleYes} />}
        >
            <div>Do you want to cancel this request?</div>
        </Modal>
    );
};

export default CancelRequest;
