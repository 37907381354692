import { useMemo } from "react";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import Icon from "../../atoms/Icon";
import { AppDispatch } from "../../../configureStore";
import { setActiveBorrower, setIsEdit, setLockBorrower } from "../../../redux/reducer/borrower";
import { IBorrower } from "../../../utils/interfaces/borrower";

interface BorrowerButtonParams {
    borrower: IBorrower;
    setOpen: (_: boolean) => void;
}

const BorrowerButton = ({
    borrower,
    setOpen,
}: BorrowerButtonParams) => {
    const count = useMemo(() => borrower.requests, [borrower]);
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div className="flex-center-center">
            <Tooltip
                title={count.length > 0 ? "Edit" : "Create"}
                trigger="hover"
                placement="left"
            >
                <div style={{ width: 'fit-content' }}>
                    <Icon
                        size={20}
                        onClick={() => {
                            dispatch(setIsEdit(count.length > 0));
                            count.length === 0 && dispatch(setLockBorrower());
                            dispatch(setActiveBorrower(borrower.id));
                            setOpen(true);
                        }}
                        name={count.length > 0 ? "EditOutlined" : "PlusOutlined"}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default BorrowerButton;
