import FormItem from "../../atoms/FormItem";

interface RIAFormParams {
}

const RIAForm = ({
}: RIAFormParams) => (
    <>
        <FormItem required label="First Name" />
        <FormItem required label="Last Name" />
        <FormItem required label="Email" />
    </>
);

export default RIAForm;
