/* eslint-disable import/no-cycle */
/* eslint-disable default-param-last */
import { Payload } from ".";
import { TWRole } from "../../components/atoms/RoleChip";

import { AppDispatch } from "../../configureStore";
import { Institution, Employee } from "../../utils/interfaces/auth";
import execute from "../http/execute";
import failure from "../http/failure";
import { get, put } from "../http/request";
import success from "../http/success";

const initialState: {
    institutions: Institution[];
    me?: Employee;
    role?: TWRole;
} = {
    institutions: [],
};

export const GET_ME_REQUEST = 'auth/GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'auth/GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'auth/GET_ME_FAILURE';

export const UPDATE_ME_REQUEST = 'auth/UPDATE_ME_REQUEST';
export const UPDATE_ME_SUCCESS = 'auth/UPDATE_ME_SUCCESS';
export const UPDATE_ME_FAILURE = 'auth/UPDATE_ME_FAILURE';

export const GET_INSTITUTIONS_REQUEST = 'auth/GET_INSTITUTIONS_REQUEST';
export const GET_INSTITUTIONS_SUCCESS = 'auth/GET_INSTITUTIONS_SUCCESS';
export const GET_INSTITUTIONS_FAILURE = 'auth/GET_INSTITUTIONS_FAILURE';

type ActionParams =
    | { type: typeof GET_ME_SUCCESS; payload: Payload; data: Employee; }
    | { type: typeof GET_INSTITUTIONS_SUCCESS; payload: Payload; data: { data: Institution[]; }; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_ME_SUCCESS:
            return {
                ...state,
                me: action.data,
                role: action.data.role,
            };

        case GET_INSTITUTIONS_SUCCESS:
            return {
                ...state,
                institutions: action.data.data,
            };

        default:
            return state;
    }
}

export const getMe = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    execute(get, '/auth/user')
        .then(success(dispatch, GET_ME_SUCCESS, payload))
        .catch(failure(dispatch, GET_ME_FAILURE, payload));

    dispatch({
        type: GET_ME_REQUEST,
        payload,
    });
};

export const updateMe = (payload: Payload<any>) => (dispatch: AppDispatch) => {
    execute(put, '/employee', payload.input)
        .then(success(dispatch, UPDATE_ME_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_ME_FAILURE, payload));

    dispatch({
        type: UPDATE_ME_REQUEST,
        payload,
    });
};

export const getInstitutions = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    execute(get, '/institution')
        .then(success(dispatch, GET_INSTITUTIONS_SUCCESS, payload))
        .catch(failure(dispatch, GET_INSTITUTIONS_FAILURE, payload));

    dispatch({
        type: GET_INSTITUTIONS_REQUEST,
        payload,
    });
};

export default reducer;
