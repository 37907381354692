import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { APIOrigin, RIAOptions } from "../interfaces/encompass";
import { IRIAForm } from "../ria";
import { Transaction } from "./transaction";
import { IBorrower } from "../interfaces/borrower";

export class RiaTransaction extends Transaction<RIAOptions> {
    constructor(
        props: IRIAForm,
        borrower: IBorrower,
        origin: APIOrigin,
        isResend: boolean,
    ) {
        const options: RIAOptions = {
            firstName: props.firstName,
            lastName: props.lastName,
            email: props.email,
            irsIncomeId: borrower.irsIncomeId,
            loanProfessional: origin.origin.originatingParty.pointOfContact,
            contacts: origin.origin?.loan?.contacts ?? '',
            channel: origin.origin?.loan?.channel ?? '',
            status: "Request Submitted",
            requestType: "RIA Request Submitted",
            instanceId: origin.instanceId,
            encompassBorrowerId: borrower.encompassBorrowerId,
            primaryBorrower: origin.origin.loan.applications[0].borrower.id,
            loanNumber: origin.origin.loan.loanNumber,
            isResend,
        };
        super(borrower.encompassBorrowerId, options);
    }

    public toTransaction(): TransactionRequest<RIAOptions> {
        return super.toTransaction();
    }
}
