import { Typography } from "antd";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../configureStore";
import { SET_ACTION_OPEN } from "../../redux/reducer/encompass";

interface ActionReasonParams {}

const ActionReason = ({
}: ActionReasonParams) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', width: '100%' }}>
            <Typography
                style={{
                    margin: 0,
                    marginRight: 4,
                    textDecoration: 'underline',
                    color: 'blue',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    width: 'fit-content',
                }}
                onClick={() => {
                    dispatch({ type: SET_ACTION_OPEN, actionOpen: true });
                }}
            >
                Why can&apos;t I edit everything?
            </Typography>
        </div>
    );
};

export default ActionReason;
