import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { ProxyFile, fileTypesReversed } from "../interfaces/encompass";
import { BusinessRequest, ICreateRequestForm, IndividualRequest } from "../interfaces/create";

export abstract class Transaction<T> {
    constructor(borrowerId: string, options: T, resources?: ProxyFile[]) {
        this.scope = {
            type: "INDIVIDUAL",
            id: borrowerId,
        };

        this.options = options;
        this.resources = resources;
    }

    type: string = "Submit Tax Wallet";

    scope: {
        type: "INDIVIDUAL";
        id: string;
    };

    options: T;

    resources?: ProxyFile[];

    public static getTaxYears(yearList: number[], formList: string[], multiYear: string): {
        form: string;
        year: string;
        multiYear: string;
    }[] {
        const mapped = (yearList ?? []).flatMap((per) => (
            (formList ?? []).map((frm) => ({
                form: frm,
                year: per > 0 ? per.toString() : '',
                multiYear: per === 0 ? multiYear : '',
            }))
        ));

        return mapped;
    }

    public static getIndividualProxyFiles(ind: IndividualRequest): ProxyFile[] {
        const files: ProxyFile[] = [];
        if (ind.transcriptAuthorization?.length) {
            ind.transcriptAuthorization.forEach((indFile) => {
                files.push({
                    ...indFile,
                    fileName: indFile.name,
                    docType: fileTypesReversed.transcriptAuthorization,
                });
            });
        }
        if (ind.governmentIssuedPhotoId?.length) {
            ind.governmentIssuedPhotoId.forEach((indFile) => {
                files.push({
                    ...indFile,
                    fileName: indFile.name,
                    docType: fileTypesReversed.governmentIssuedPhotoId,
                });
            });
        }
        if (ind.selfTakenPicture?.length) {
            ind.selfTakenPicture.forEach((indFile) => {
                files.push({
                    ...indFile,
                    fileName: indFile.name,
                    docType: fileTypesReversed.selfTakenPicture,
                });
            });
        }
        return files;
    }

    public static getBusinessProxyFiles(biz: BusinessRequest): ProxyFile[] {
        const files: ProxyFile[] = [];
        if (biz.businessTranscriptAuthorization?.length) {
            biz.businessTranscriptAuthorization.forEach((bizFile) => {
                files.push({
                    ...bizFile,
                    fileName: bizFile.name,
                    docType: fileTypesReversed.businessTranscriptAuthorization,
                });
            });
        }
        if (biz.businessGovernmentIssuedPhotoId?.length) {
            biz.businessGovernmentIssuedPhotoId.forEach((bizFile) => {
                files.push({
                    ...bizFile,
                    fileName: bizFile.name,
                    docType: fileTypesReversed.businessGovernmentIssuedPhotoId,
                });
            });
        }
        if (biz.businessSelfTakenPicture?.length) {
            biz.businessSelfTakenPicture.forEach((bizFile) => {
                files.push({
                    ...bizFile,
                    fileName: bizFile.name,
                    docType: fileTypesReversed.businessSelfTakenPicture,
                });
            });
        }
        return files;
    }

    public static getProxyFiles(values: ICreateRequestForm): ProxyFile[] {
        const files: ProxyFile[] = [];
        if (values.transcriptAuthorization) {
            files.push({
                ...values.transcriptAuthorization,
                fileName: values.transcriptAuthorization.name,
                docType: fileTypesReversed.transcriptAuthorization,
            });
        }
        if (values.governmentIssuedPhotoId) {
            files.push({
                ...values.governmentIssuedPhotoId,
                fileName: values.governmentIssuedPhotoId.name,
                docType: fileTypesReversed.governmentIssuedPhotoId,
            });
        }
        if (values.selfTakenPicture) {
            files.push({
                ...values.selfTakenPicture,
                fileName: values.selfTakenPicture.name,
                docType: fileTypesReversed.selfTakenPicture,
            });
        }
        if (values.businessTranscriptAuthorization) {
            files.push({
                ...values.businessTranscriptAuthorization,
                fileName: values.businessTranscriptAuthorization.name,
                docType: fileTypesReversed.businessTranscriptAuthorization,
            });
        }
        if (values.businessGovernmentIssuedPhotoId) {
            files.push({
                ...values.businessGovernmentIssuedPhotoId,
                fileName: values.businessGovernmentIssuedPhotoId.name,
                docType: fileTypesReversed.businessGovernmentIssuedPhotoId,
            });
        }
        if (values.businessSelfTakenPicture) {
            files.push({
                ...values.businessSelfTakenPicture,
                fileName: values.businessSelfTakenPicture.name,
                docType: fileTypesReversed.businessSelfTakenPicture,
            });
        }
        return files;
    }

    public static getAllProxyFiles(_individual: any): ProxyFile[] {
        const files: ProxyFile[] = [];
        return files;
    }

    public toTransaction(): TransactionRequest<T> {
        return {
            request: {
                type: this.type,
                scope: this.scope,
                options: this.options,
                ...this.resources && { resources: this.resources },
            },
        };
    }
}
