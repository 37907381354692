/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
    Form,
    Input,
    InputProps,
    Select,
    SelectProps,
} from "antd";
import { Rule } from "antd/es/form";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
import { useMemo, useState } from "react";

import { toCamelCase } from "../../utils/helpers";
import { RootState } from "../../configureStore";

interface FormInputParams extends Omit<InputProps, 'value' | 'id' | 'name'> {
    type?: 'Input';
    name?: string;
    required?: boolean;
    label: string;
    extra?: string
    rules?: Rule[];
    index?: number;
    compact?: boolean;
    showSearch?: boolean;
    htmlType?: 'button' | 'checkbox' | 'color' | 'date' | 'number' | 'file' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';
}

interface FormSelectParams extends Omit<SelectProps, 'value' | 'id'> {
    type?: 'Select';
    name?: string;
    required?: boolean;
    label: string;
    extra?: string
    rules?: Rule[];
    index?: number;
    compact?: boolean;
    showSearch?: boolean;
    htmlType?: 'button' | 'checkbox' | 'color' | 'date' | 'number' | 'file' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';
}

interface FormMaskParams {
    type?: 'Mask';
    name?: string;
    required?: boolean;
    label: string;
    disabled?: boolean;
    extra?: string
    rules?: Rule[];
    mask: string;
    index?: number;
    compact?: boolean;
    showSearch?: boolean;
    htmlType?: 'button' | 'checkbox' | 'color' | 'date' | 'number' | 'file' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week';
}

export type FormItemParams = FormInputParams | FormSelectParams | FormMaskParams;

const RequiredMark = () => <span style={{ lineHeight: '10px', color: 'red', paddingLeft: '1px' }}>*</span>;

const FormItem = ({
    name,
    label,
    type = 'Input',
    required = false,
    extra,
    rules = [],
    index,
    compact = false,
    showSearch = false,
    htmlType,
    ...otherProps
}: FormItemParams) => {
    const [hover, setHover] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const enabled = useSelector<RootState, string[] | undefined>(({ borrower: { activeBorrower: ac, borrowers: b }, configuration: { actionReasons: ar } }) => ar[(b[ac || '']?.actionReason?.actionCode) || '']?.enabled);
    const isFix = useSelector<RootState, boolean>(({ borrower: { isFix: isF } }) => isF);
    const useName = useMemo(() => (index != null ? [index, name || toCamelCase(label)] : name || toCamelCase(label)), [name, index, label]);

    const fieldEnabled = useMemo<boolean>(() => Boolean(enabled?.length && enabled.includes(String(Array.isArray(useName) ? useName[useName.length - 1] : useName))), [useName, enabled]);

    if (required && !rules.some((rle: Rule) => (rle as any).required)) rules.push({ required: true, message: `${label} is required` });

    return (
        <Form.Item
            className={`hsw-form-item ${compact ? '' : 'mb-8'} mt-2`}
            extra={extra}
        >
            {
                type === 'Select' ? (
                    <Form.Item
                        noStyle
                        name={useName}
                        rules={rules}
                    >
                        <Select
                            // @ts-ignore
                            onMouseEnter={() => { !otherProps.disabled && setHover(true); }}
                            onMouseLeave={() => { !otherProps.disabled && setHover(false); }}
                            onFocus={() => { !otherProps.disabled && setFocus(true); }}
                            onBlur={() => { !otherProps.disabled && setFocus(false); }}
                            showSearch={showSearch}
                            placeholder={label}
                            disabled={isFix && !fieldEnabled}
                            className={(isFix && fieldEnabled) ? "danger--border" : undefined}
                            {...otherProps}
                        />
                    </Form.Item>
                ) : type === 'Mask' ? (
                    <Form.Item
                        noStyle
                        name={useName}
                        rules={rules}
                    >
                        <InputMask
                            mask={(otherProps as any).mask}
                            maskChar="_"
                            onMouseEnter={() => { !otherProps.disabled && setHover(true); }}
                            onMouseLeave={() => { !otherProps.disabled && setHover(false); }}
                            onFocus={() => { !otherProps.disabled && setFocus(true); }}
                            onBlur={() => { !otherProps.disabled && setFocus(false); }}
                            placeholder={label}
                            className={(isFix && fieldEnabled) ? "danger--border" : undefined}
                            disabled={isFix && !fieldEnabled}
                        >
                            {
                                // @ts-ignore
                                (inputProps) => (
                                    <Input
                                        disabled={isFix && !fieldEnabled}
                                        {...inputProps}
                                    />
                                )
                            }
                        </InputMask>
                    </Form.Item>
                ) : (
                    <Form.Item
                        noStyle
                        name={useName}
                        rules={rules}
                    >
                        <Input
                            onMouseOver={() => { !otherProps.disabled && setHover(true); }}
                            onMouseLeave={() => { !otherProps.disabled && setHover(false); }}
                            onFocus={() => { !otherProps.disabled && setFocus(true); }}
                            onBlur={() => { !otherProps.disabled && setFocus(false); }}
                            // @ts-ignore
                            placeholder={label}
                            disabled={isFix && !fieldEnabled}
                            className={(isFix && fieldEnabled) ? "danger--border" : undefined}
                            type={htmlType}
                            {...otherProps}
                        />
                    </Form.Item>
                )
            }

            <label className={`hsw-label ${((isFix && fieldEnabled) && !(hover || focus)) ? ' hsw-label-danger' : ''}${(hover || focus) ? ' hsw-label-hover' : ''}${otherProps.disabled ? ' hsw-label-disabled' : ''}`}>
                {label}
                {required ? <RequiredMark /> : ''}
            </label>
        </Form.Item>
    );
};

export default FormItem;
