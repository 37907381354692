import { Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { ColumnType } from 'antd/lib/table';

import { ApplicantStatusHistory } from '../../utils/interfaces/originv2';
import { AppDispatch, RootState } from '../../configureStore';
import { setActiveBorrower, setCustomerHistory } from '../../redux/reducer/borrower';
import { Header } from '../atoms/ModalHeader';

interface BorrowerHistoryParams {
    open: boolean;
}

const BorrowerHistory = ({
    open,
}: BorrowerHistoryParams) => {
    const history = useSelector<RootState, ApplicantStatusHistory[]>(({ borrower }) => borrower?.borrowers?.[borrower.activeBorrower || '']?.history ?? []);
    const latestAttempt = useSelector<RootState, Date | undefined>(({ borrower }) => borrower?.borrowers?.[borrower.activeBorrower || '']?.latestAttempt);
    const dispatch = useDispatch<AppDispatch>();

    const columns: ColumnType<ApplicantStatusHistory>[] = [
        {
            title: 'Timestamp',
            dataIndex: 'eventTime',
            align: 'center',
            render: (dt: string) => DateTime.fromJSDate(new Date(dt)).toFormat('D tt'),
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a: ApplicantStatusHistory, b: ApplicantStatusHistory) => (new Date(a.eventTime).getTime() - new Date(b.eventTime).getTime()),
        },
        {
            title: 'Event',
            dataIndex: 'status',
            align: 'center',
            render: (v: string) => v.replace('TIA ', ''),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            align: 'left',
        },
    ];

    return (
        <Modal
            open={open}
            footer={null}
            onCancel={() => {
                dispatch(setActiveBorrower(''));
                dispatch(setCustomerHistory(false));
            }}
            title={latestAttempt ? <Header withIrs title="History" latestAttempt={latestAttempt} /> : "History"}
            width="75%"
        >
            <Table
                className="mt-4"
                columns={columns}
                dataSource={history || []}
                rowKey="eventTime"
            />
        </Modal>
    );
};

export default BorrowerHistory;
