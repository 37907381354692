/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import auth from "./auth";
import borrower from "./borrower";
import configuration from "./configuration";
import encompass from "./encompass";
import request from "./request";
import status from "./status";

export interface Payload<T = any> {
    onSuccess?: (data: any) => void;
    onError?: (data: any) => void;
    headers?: T;
    input?: T;
    data?: T;
    noLoad?: boolean;
}

const rootReducer = combineReducers({
    auth,
    borrower,
    configuration,
    encompass,
    request,
    status,
});

export default rootReducer;
