import { Col, Row, Tooltip, message } from "antd";
import { useSelector } from "react-redux";
import FormItem from "../atoms/FormItem";
import Icon from "../atoms/Icon";
import { STATES, textToClipboard } from "../../utils/helpers";
import { RootState } from "../../configureStore";

interface AddressInformationParams {
    index?: number;
}

const AddressInformation = ({
    index,
}: AddressInformationParams) => {
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);

    return (
        <div className={isAdd ? 'none' : 'form-section'}>
            <h5 style={{ marginBottom: 0 }}>*Address on last filed tax return*</h5>
            <p className="mb-2 mt-0">
                <small>
                    This address can be looked up by the borrower on this IRS link:
                    {' '}
                    <span style={{ textDecoration: 'underline' }}>https://sa.www4.irs.gov/ola/profile</span>
                    {' '}
                </small>
                <Tooltip title="Copy Url">
                    <Icon
                        className="mb-2"
                        name="CopyOutlined"
                        onClick={() => {
                            textToClipboard("https://sa.www4.irs.gov/ola/profile");
                            message.open({
                                type: 'success',
                                content: 'Url Copied',
                            });
                        }}
                    />
                </Tooltip>
            </p>
            <Row gutter={8}>
                <Col span={12}>
                    <FormItem
                        label="Address Line 1"
                        name="addressLineOne"
                        required
                        index={index}
                    />
                </Col>
                <Col span={12}>
                    <FormItem
                        label="Address Line 2"
                        name="addressLineTwo"
                        index={index}
                    />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={8}>
                    <FormItem
                        label="City"
                        required
                        index={index}
                    />
                </Col>
                <Col span={8}>
                    <FormItem
                        type="Select"
                        label="State"
                        required
                        options={STATES}
                        index={index}
                        showSearch
                    />
                </Col>
                <Col span={8}>
                    <FormItem
                        label="Postal Code"
                        required
                        index={index}
                        maxLength={5}
                        rules={[{ pattern: /^\d{5}$/, message: 'Please provide a valid United States Postal Code' }]}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default AddressInformation;
