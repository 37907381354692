import { Tooltip, notification } from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIOrigin } from "../../../utils/interfaces/encompass";
import { AppDispatch, RootState } from "../../../configureStore";
import { setActiveBorrower, setRequestId } from "../../../redux/reducer/borrower";
import Icon from "../../atoms/Icon";
import { ResubmitTransaction } from "../../../utils/encompass/resubmitTransaction";
import { IBorrower } from "../../../utils/interfaces/borrower";
import { sendEncompassRequest } from "../../../redux/reducer/encompass";
import { RequestV2 } from "../../../utils/interfaces/originv2";

interface RequestButtonParams {
    request: RequestV2;
    setOpenCancel: (_: boolean) => void;
}

const RequestButton = ({
    request,
    setOpenCancel,
}: RequestButtonParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const borrower = useSelector<RootState, IBorrower | undefined>(({ borrower: { borrowers } }) => borrowers[request.irsIncomeId]);
    const origin = useSelector<RootState, APIOrigin | undefined>(({ encompass }) => encompass.origin);
    const [loading, setLoading] = useState<boolean>(false);

    const isReady = useMemo(() => request.status === 'Ready', [request.status]);

    const cancel = () => {
        dispatch(setRequestId(request.requestId));
        dispatch(setActiveBorrower(request.irsIncomeId));
        setOpenCancel(true);
    };

    const reorder = () => {
        if (borrower && origin) {
            setLoading(true);
            const reorderTxn: ResubmitTransaction = new ResubmitTransaction(
                request.requestId,
                {
                    form: request.formDescription,
                    multiYear: request.taxYear.startsWith('Year') ? request.taxYear : "",
                    year: !request.taxYear.startsWith('Year') ? request.taxYear : "",
                },
                borrower,
                origin,
            );
            dispatch(
                sendEncompassRequest({
                    input: reorderTxn,
                    onSuccess: () => {
                        notification.success({ message: 'Successfully reordered.' });
                        setTimeout(() => { setLoading(false); window.location.reload(); }, 2000);
                    },
                    onError: () => {
                        notification.error({ message: 'Something went wrong!' });
                        setLoading(false);
                    },
                }),
            );
        }
    };

    return (
        <div className="flex-center-center">
            <Tooltip
                title={isReady ? "Reorder" : "Cancel Request"}
                trigger="hover"
                placement="left"
            >
                <div>
                    <Icon
                        size={20}
                        onClick={isReady ? reorder : cancel}
                        name={isReady ? (loading ? "LoadingOutlined" : "RedoOutlined") : "CloseOutlined"}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default RequestButton;
