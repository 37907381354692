import { Button, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../configureStore";
import {
    SET_ACTION_OPEN,
    closeTransaction,
    getOrigin,
    getTransactionOrigin,
    refreshOrigin,
    sendEncompassRequest,
} from "../../redux/reducer/encompass";
import { IHostConnection } from "../../utils/em-host";
import EncompassTable from "../organisms/EncompassTable";
import EncompassOrderForm from "../organisms/EncompassOrderForm";
import { APIOrigin, TransactionOrigin } from "../../utils/interfaces/encompass";
import { EFolderTransaction } from "../../utils/encompass/eFolderTransaction";
import CancelRequest from "../organisms/CancelRequest";
import ActionNeededModal from "../organisms/ActionNeededModal";
import BorrowerHistory from "../organisms/BorrowerHistory";

interface EncompassTemplateParams {
}

const EncompassTemplate = ({
}: EncompassTemplateParams) => {
    const [open, setOpen] = useState<boolean>(false);
    const [openCancel, setOpenCancel] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();
    const host = useSelector<RootState, IHostConnection | undefined>(({ encompass }) => encompass.host);
    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);
    const origin = useSelector<RootState, APIOrigin | undefined>(({ encompass }) => encompass.origin);
    const originLoading = useSelector<RootState, boolean>(({ status }) => status.loading['encompass/GET_ORIGIN']);
    const showCopyToEFolder = useSelector<RootState, boolean>(({ encompass }) => Boolean(encompass.origin?.isShowCopyToEfolder));
    const txnSuccess = useSelector<RootState, boolean>(({ status }) => status.success['encompass/GET_TXN_ORIGIN']);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['encompass/CREATE_TRANSACTION']);
    const actionOpen = useSelector<RootState, boolean>(({ encompass }) => encompass.actionOpen);
    const historyOpen = useSelector<RootState, boolean>(({ borrower }) => borrower.historyOpen);

    useEffect(() => {
        if (host) {
            dispatch(getTransactionOrigin());
        }
    }, [host, dispatch]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(refreshOrigin({
                onSuccess: (res) => {
                    dispatch(getOrigin({
                        input: {
                            partnerAccessToken: res.partnerAccessToken,
                            originId: res.id,
                            version: 2,
                        },
                    }));
                },
            }));
        }, 290000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (txnSuccess && txnOrgn && !originLoading) {
            dispatch(getOrigin({
                input: {
                    partnerAccessToken: txnOrgn.partnerAccessToken,
                    originId: txnOrgn.id,
                    version: 2,
                },
            }));
        }
    }, [
        dispatch,
        txnSuccess,
        txnOrgn,
    ]);

    const resubmitRequest = async () => {
        if (host && origin) {
            const eFolderTxn: EFolderTransaction = new EFolderTransaction(origin);
            dispatch(
                sendEncompassRequest({
                    input: eFolderTxn,
                    onSuccess: () => {
                        notification.success({
                            message: 'Copying Transcripts... Please check your e-folder in a moment. Thank you!',
                        });
                    },
                }),
            );
        }
    };

    useEffect(() => {
        const lastRefresh = localStorage.getItem('LAST_REFRESH');
        switch (lastRefresh) {
            case 'CHECK_AUTH':
                notification.success({
                    description: 'Allow a few minutes for processing.',
                    message: 'Request received',
                });
                break;
            case 'RESEND_AUTH':
                notification.success({
                    message: 'The request has been sent. Please ask the borrower to complete the authorization.',
                });
                break;
            default:
                break;
        }

        localStorage.removeItem('LAST_REFRESH');
    }, []);

    return (
        <div style={{ width: '80%', margin: '16px auto' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <BorrowerHistory open={historyOpen} />
                <ActionNeededModal
                    open={actionOpen}
                    setOpen={(b) => { dispatch({ type: SET_ACTION_OPEN, actionOpen: b }); }}
                />
                <EncompassOrderForm
                    open={open}
                    setOpen={setOpen}
                />
                <CancelRequest
                    open={openCancel}
                    setOpen={setOpenCancel}
                />
                <Button
                    type="primary"
                    disabled={loading}
                    onClick={() => { setOpen(true); }}
                >
                    Order Transcript
                </Button>

                <span className="align-right">
                    {showCopyToEFolder.valueOf()}
                    {showCopyToEFolder && (
                        <Button
                            type="primary"
                            onClick={() => { resubmitRequest(); }}
                            style={{ marginRight: 8 }}
                            loading={loading}
                        >
                            Copy to e-folder
                        </Button>
                    )}

                    <Button
                        type="primary"
                        id="btnrefresh"
                        onClick={() => { window.location.reload(); }}
                        disabled={loading}
                        style={{ marginRight: 8 }}
                    >
                        Refresh
                    </Button>
                    <Button
                        type="primary"
                        id="btnclose"
                        disabled={loading}
                        onClick={() => { dispatch(closeTransaction()); }}
                    >
                        Close
                    </Button>
                </span>
            </div>
            <EncompassTable
                setOpen={setOpen}
                setOpenCancel={setOpenCancel}
            />
        </div>
    );
};

export default EncompassTemplate;
