import { Form, Modal } from "antd";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import riaText, { IRIAForm } from "../../../utils/ria";
import { RootState } from "../../../configureStore";
import RIAForm from "./RIAForm";
import { IBorrower } from "../../../utils/interfaces/borrower";
import RIAFooter from "./RIAFooter";

interface RIAInvitationParams {
    open: boolean;
    setOpen: (_: boolean) => void;
    sendInvitation: (values: IRIAForm, borrower: IBorrower) => void;
}

const RIAInvitation = ({
    open,
    setOpen,
    sendInvitation,
}: RIAInvitationParams) => {
    const [step, setStep] = useState<number>(0);
    const [read, setRead] = useState<boolean>(false);
    const institution = useSelector<RootState, string>(({ configuration }) => configuration.institutionName || '');
    const borrower = useSelector<RootState, IBorrower | undefined>(({ borrower: { borrowers, activeBorrower } }) => borrowers[activeBorrower || '']);

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
        if (bottom) {
            setTimeout(() => {
                setRead(true);
            }, 500);
        }
    };

    const riaTextObj = useMemo(() => riaText(institution), []);

    const handleClose = () => {
        setOpen(false);
        setRead(false);
        setStep(0);
    };

    const [form] = Form.useForm<IRIAForm>();

    return (
        <Modal
            destroyOnClose
            title="Connect to Investment Advisor"
            footer={null}
            open={open}
            onCancel={handleClose}
            maskClosable={false}
            width={step ? undefined : '75%'}
        >
            <Form
                form={form}
                className={!step ? "" : "mt-6"}
                initialValues={borrower}
                onFinish={(values) => {
                    if (borrower) sendInvitation(values, borrower);
                }}
            >
                {
                    !step ? (
                        <div
                            style={{ height: 400, overflowY: 'scroll' }}
                            onScroll={handleScroll}
                        >
                            {
                                riaTextObj.text.map((p: string, i: number) => <p key={`ria-${i}`}>{p}</p>)
                            }
                        </div>
                    ) : <RIAForm />
                }
                <RIAFooter
                    read={read}
                    isForm={step > 0}
                    handleClose={handleClose}
                    agree={() => { setStep(1); }}
                />
            </Form>
        </Modal>
    );
};

export default RIAInvitation;
