export interface IColors {
    primary: string;
    secondary: string;
    tertiary: string;
}

export const defaultColors: IColors = {
    primary: '#1890FF',
    secondary: '#25C0A4',
    tertiary: '#E9EBF5',
};
