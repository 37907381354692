import { Card, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { closeTransaction, getHost } from "../redux/reducer/encompass";
import store, { RootState } from "../configureStore";
import EncompassTemplate from "../components/templates/EncompassTemplate";
import { clearError } from "../redux/reducer/status";
import LoadingOverlay from "../components/atoms/LoadingOverlay";
import NoAccess from "../components/templates/NoAccess";
import Icon from "../components/atoms/Icon";

interface EncompassParams {
}

const EncompassApp = ({
}: EncompassParams) => {
    const dispatch = useDispatch<any>();
    const fullLoading = false;
    const [cspIssue, setCspIssue] = useState<boolean>(false);

    const hostSuccess = useSelector<RootState, boolean>(({ encompass }) => Boolean(encompass.host));
    const unauth = useSelector<RootState, boolean>(({ encompass }) => Boolean(encompass.unauthorized));
    const error = useSelector<RootState, string | undefined>(({ status }) => status.error);
    // const successful = useSelector<RootState, string | undefined>(({ status }) => status.successful);

    useEffect(() => {
        dispatch(getHost());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            notification.error({
                message: error,
            });
            dispatch(clearError());
        }
    }, [error]);

    return unauth
        ? (
            <div>
                {cspIssue && (
                    <div className="flex-center-center">
                        <Card
                            style={{
                                flexDirection: 'column',
                                backgroundColor: '#FF0044',
                                width: 'fit-content',
                                padding: 24,
                            }}
                            size="small"
                        >
                            <div style={{ position: 'absolute', top: 8, right: 8 }}>
                                <Icon name="CloseOutlined" onClick={() => { setCspIssue(false); }} />
                            </div>
                            <Typography.Title style={{ marginTop: 0 }} level={2}>We are experiencing issues with our Cloud Service Provider</Typography.Title>
                            <Typography>You may experience limited functionality. Thank you for your patience while we work with them to fix this issue!</Typography>
                        </Card>
                    </div>
                )}
                <NoAccess close={() => { dispatch(closeTransaction()); }} />
            </div>
        )
        : (
            <>
                {fullLoading && <LoadingOverlay />}
                <div className="App">
                    {cspIssue && (
                        <div className="flex-center-center">
                            <Card
                                style={{
                                    flexDirection: 'column',
                                    backgroundColor: '#FF0044',
                                    width: 'fit-content',
                                    padding: 24,
                                }}
                                size="small"
                            >
                                <div style={{ position: 'absolute', top: 8, right: 8 }}>
                                    <Icon name="CloseOutlined" onClick={() => { setCspIssue(false); }} />
                                </div>
                                <Typography.Title style={{ marginTop: 0 }} level={2}>We are experiencing issues with our Cloud Service Provider</Typography.Title>
                                <Typography>You may experience limited functionality. Thank you for your patience while we work with them to fix this issue!</Typography>
                            </Card>
                        </div>
                    )}
                    {
                        hostSuccess ? (
                            <EncompassTemplate />
                        ) : (
                            <LoadingOverlay />
                        )
                    }
                </div>
            </>
        );
};

const Encompass = () => (
    <Provider store={store}>
        <EncompassApp />
    </Provider>
);

export default Encompass;
