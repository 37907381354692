import { Configuration, LogLevel, BrowserCacheLocation } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        redirectUri: process.env.REACT_APP_BASE_URI ?? 'http://localhost:3000/', // apiConfig.msalRedirectUrl,
        clientId: process.env.REACT_APP_TOKEN_AUDIENCE ?? 'e3ab3c68-323a-4c5c-8268-f92dc493e3c8', // apiConfig.msalClientId,
        authority: 'https://login.microsoftonline.com/organizations/',
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowNativeBroker: false,
        allowRedirectInIframe: true,
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        break;
                    case LogLevel.Info:
                        break;
                    case LogLevel.Verbose:
                        break;
                    case LogLevel.Warning:
                        console.warn(message);
                        break;
                    default:
                        break;
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE ?? 'e3ab3c68-323a-4c5c-8268-f92dc493e3c8'}/api`, 'GroupMember.Read.All'],
};

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
