import sandbox from "../../sandbox";

const execute = async (apiCall: any, path: string, payload?: any, headers?: any) => {
    const viewAs: string | null = sandbox.get('viewAs');

    try {
        const response = await apiCall(path, payload, viewAs, headers);
        if (response.body instanceof ReadableStream) {
            try {
                const body = await response.json();
                return {
                    ...payload,
                    data: body,
                };
            } catch (error: any) {
                return {
                    ...payload,
                    ...error,
                };
            }
        }
        return {
            ...payload,
            ...response,
        };
    } catch (error: any) {
        // eslint-disable-next-line no-throw-literal
        throw {
            ...payload,
            ...error,
        };
    }
};

export default execute;
