import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { CheckAuthTransactionOptions } from "../interfaces/encompass";
import { Transaction } from "./transaction";

export class CheckAuthTransaction extends Transaction<CheckAuthTransactionOptions> {
    constructor(
        loanNumber: string,
        irsIncomeId: string,
        encompassBorrowerId: string,
        instanceId: string,
    ) {
        super(
            encompassBorrowerId,
            {
                loanNumber,
                irsIncomeId,
                instanceId,
                type: "Request Authorization Check",
                requestType: "Check Authorization",
            },
            [],
        );
    }

    public toTransaction(): TransactionRequest<CheckAuthTransactionOptions> {
        return super.toTransaction();
    }
}
