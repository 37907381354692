import { FormListFieldData } from "antd";
import { useSelector } from "react-redux";

import AddTranscript from "./AddTranscript";
import { RootState } from "../../configureStore";
import BusinessForm from "./BusinessForm";

interface BusinessTranscriptParams {
    fields: FormListFieldData[];
    change: (action: 'remove' | 'add', toRemove?: FormListFieldData) => void;
    open: Set<number>;
    onOpenChange: (field: FormListFieldData) => void;
}

const BusinessTranscript = ({
    fields,
    change,
    open,
    onOpenChange,
}: BusinessTranscriptParams) => {
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);

    return (
        <>
            <div>
                {fields.map((field, idx) => (
                    <BusinessForm
                        open={open}
                        change={change}
                        onOpenChange={onOpenChange}
                        field={field}
                        idx={idx}
                    />
                ))}
            </div>
            {(!isEdit && !isAdd) && (
                <AddTranscript
                    isBusiness
                    add
                    onClick={() => {
                        change('add');
                    }}
                />
            )}
        </>
    );
};

export default BusinessTranscript;
