import { TransactionRequest } from "@elliemae/em-ssf-guest";
import { APIOrigin, ResendAuthorizationOptions } from "../interfaces/encompass";
import { Transaction } from "./transaction";
import { IBorrower } from "../interfaces/borrower";

export class ResendAuthorizationTransaction extends Transaction<ResendAuthorizationOptions> {
    constructor(
        borrower: IBorrower,
        origin: APIOrigin,
        irsIncomeId?: string,
    ) {
        super(
            borrower.encompassBorrowerId,
            {
                irsIncomeId,
                loanNumber: origin.origin.loan.loanNumber,
                tin: borrower.tin,
                firstName: borrower.firstName,
                lastName: borrower.lastName,
                loanProfessional: origin.origin.originatingParty.pointOfContact,
                status: "Resend Authorization",
                requestType: "Resend Authorization",
                instanceId: origin.instanceId,
            },
        );
    }

    public toTransaction(): TransactionRequest<ResendAuthorizationOptions> {
        return super.toTransaction();
    }
}
