import { Button, Col, Row } from "antd";

interface YesNoButtonsParams {
    loading: boolean;
    handleNo: () => void;
    handleYes: () => void;
}

const YesNoButtons = ({
    loading,
    handleNo,
    handleYes,
}: YesNoButtonsParams) => (
    <Row gutter={8}>
        <Col>
            <Button loading={loading} type="primary" onClick={() => { handleYes(); }}>
                Yes
            </Button>
        </Col>
        <Col>
            <Button type="default" onClick={() => { handleNo(); }}>
                No
            </Button>
        </Col>
    </Row>
);

export default YesNoButtons;
