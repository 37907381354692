import { CSSProperties } from "react";

interface DashboardParams {
    style: CSSProperties;
}

const Dashboard = ({
    style,
}: DashboardParams) => (
    <svg style={style} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 0.5H7.16667V8.83333H0.5V0.5ZM15.5 0.5H8.83333V5.5H15.5V0.5ZM5.5 7.16667V2.16667H2.16667V7.16667H5.5ZM13.8333 3.83333V2.16667H10.5V3.83333H13.8333ZM13.8333 8.83333V13.8333H10.5V8.83333H13.8333ZM5.5 13.8333V12.1667H2.16667V13.8333H5.5ZM15.5 7.16667H8.83333V15.5H15.5V7.16667ZM0.5 10.5H7.16667V15.5H0.5V10.5Z"
            // fill="#7A869A"
            fill="currentColor"
        />
    </svg>
);

export default Dashboard;
