import { Button, Col, Row } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../configureStore";

interface RIAFooterParams {
    read: boolean;
    isForm: boolean;
    handleClose: () => void;
    agree: () => void;
}

const RIAFooter = ({
    read,
    isForm,
    handleClose,
    agree,
}: RIAFooterParams) => {
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['encompass/CREATE_RIA_TRANSACTION']);

    return (
        <Row gutter={8} style={{ width: '100%' }}>
            <Col>
                {
                    isForm && (
                        <Button
                            id="ria-submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Send Request
                        </Button>
                    )
                }
                {
                    !isForm && (
                        <Button
                            id="ria-agree"
                            type="primary"
                            disabled={!read}
                            onClick={(e) => {
                                e.stopPropagation();
                                agree();
                            }}
                            loading={loading}
                        >
                            I agree
                        </Button>
                    )
                }
            </Col>
            <Col>
                <Button type="default" onClick={handleClose}>Close</Button>
            </Col>
        </Row>
    );
};

export default RIAFooter;
