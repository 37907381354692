import { Button, Modal, Typography } from "antd";
import { useSelector } from "react-redux";

import { RootState } from "../../configureStore";
import { ActionReason } from "../../utils/interfaces/originv2";
import ActionNeededReason from "../molecules/ActionNeededReason";

interface ActionNeededModalParams {
    open: boolean;
    setOpen: (_: boolean) => void;
}

const ActionNeededModal = ({
    open,
    setOpen,
}: ActionNeededModalParams) => {
    const actionReasons = useSelector<RootState, { [actionCode: string]: ActionReason }>(({ configuration: { actionReasons: ar } }) => ar);
    const actionCode = useSelector<RootState, ActionReason | undefined>(({ borrower: { borrowers, activeBorrower }, configuration: { actionReasons: ar } }) => ar[borrowers[activeBorrower || '']?.actionReason?.actionCode || '']);

    return (
        <Modal
            zIndex={1010}
            open={open}
            footer={(
                <Button
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Close
                </Button>
            )}
            width="95%"
            styles={{
                mask: {
                    paddingTop: 0,
                },
            }}
            onCancel={() => {
                setOpen(false);
            }}
        >
            {
                actionCode ? (
                    <div className="mx-4">
                        <Typography.Title level={5}>
                            We noticed an issue with this order. Please review the following and make the necessary changes.
                        </Typography.Title>
                        <ActionNeededReason
                            key={actionCode.actionCode}
                            reason={actionCode}
                        />
                    </div>
                ) : (
                    Object.values(actionReasons).map((reason) => (
                        <ActionNeededReason
                            key={reason.actionCode}
                            reason={reason}
                        />
                    ))
                )
            }
        </Modal>
    );
};

export default ActionNeededModal;
