import { ActionReason } from "./originv2";

export interface RequestSummary {
    type: 'Processing' | 'Fulfilled' | 'Needs Attention';
    value: number;
}

export const initialSummary: [RequestSummary, RequestSummary, RequestSummary] = [{ type: "Processing", value: 0 }, { type: "Fulfilled", value: 0 }, { type: "Needs Attention", value: 0 }];

export interface IBusinessInformation {
    businessName: string;
    ein: string;
    businessSignedAuthorizationPdf: string;
    businessSignedAuthorizationPdfFilename: string;
    businessGovernmentPhotoId: string;
    businessGovernmentPhotoIdFilename: string;
    businessSelfTakenPicture: string;
    businessSelfTakenPictureFilename: string;
    sendBusinessEntityEmailToGetDocument: boolean;
    isSamePerson: boolean;
    businessTranscriptAuthorizationPdf: string;
    businessTranscriptAuthorizationPdfFilename: string;
}

export interface IRequest {
    id: string;
    requestId: string;
    ssn?: string;
    actionReasons: ActionReason[];
    loanNumber: string;
    status: string;
    reportId: string;
    taxPeriod: string;
    isMultiYear: boolean;
    taxPayerType: 'Business' | 'Individual';
    formNumber: string;
    formDescription: string;
    productType: string;
    irsIncomeId: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    state: string;
    name: string;
    firstName: string;
    lastName: string;
    createdTime: string;
    hasIRSAccount?: boolean;
    email?: string;
    businessEmail?: string;
}

export type TPT = 'Individual' | 'Business';

export interface IAppBorrower {
    id: string;
    loanNumber: string;
    uiType: 'Borrower';
    irsIncomeId: string;
    name: string;
    borrowerName: string;
    firstName: string;
    lastName: string;
    tin: string;
    email?: string;
    taxPayerType: TPT;
    children: IRequest[];
    actionReasons?: ActionReason
}

export interface ILoan {
    id: string;
    uiType: 'Loan';
    loanNum: string;
    loanNumber: string;
    children: IAppBorrower[];
    requests: IRequest[];
}
