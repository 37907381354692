import { Checkbox, Col, Form, Row, Select } from "antd";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { taxYears } from "../../utils/helpers";
import { RootState } from "../../configureStore";
import { IBorrower } from "../../utils/interfaces/borrower";

interface FormTypesParams {
    setHasBusinessForm?: (_: boolean) => void;
    style?: CSSProperties;
    index?: number;
    type?: 'Individual' | 'Business';
    existing?: boolean;
}

const indForms = [
    '1040 - Return Transcript',
    '1040 - Record of Account',
    'W-2 - Employee Earnings',
    '1099 - Self-Employed Earnings',
];

const bussForms = [
    '1065 - Partnership Return Transcript',
    '1065 - Partnership Record of Account',
    '1120 - Corporate Return Transcript',
    '1120 - Corporate Record of Account',
    '1120S - S Corporation Return Transcript',
];

const formTypes = [
    ...indForms,
    ...bussForms,
    'All Income Data',
];

const FormTypes = ({
    setHasBusinessForm,
    style = {},
    index,
    type,
    existing = false,
}: FormTypesParams) => {
    const isAdd = useSelector<RootState, boolean>(({ borrower: { isAdd: ia } }) => ia);
    const isEdit = useSelector<RootState, boolean>(({ borrower: { isEdit: ie } }) => ie);
    const borrower = useSelector<RootState, IBorrower | undefined>(({ borrower: { activeBorrower, borrowers } }) => (activeBorrower ? borrowers[activeBorrower] : undefined));

    const [multiDisabled, setMultiDisabled] = useState<boolean>(false);
    const [singleDisabled, setSingleDisabled] = useState<boolean>(true);

    useEffect(() => {
        setMultiDisabled(false);
        setSingleDisabled(true);
    }, [borrower]);

    const formOptions = useMemo<string[]>(() => {
        if (!type) return formTypes;
        if (type === 'Business') return bussForms;
        return [...indForms, 'All Income Data'];
    }, [type]);

    return (
        <div style={{ ...style, height: '93%' }} className="form-section">
            <h5>Form Types & Available Years *</h5>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        name={index != null ? [index, "formList"] : "formList"}
                        required
                        rules={[{ required: isEdit ? false : !(isAdd && existing), message: 'Form Type is required.' }]}
                        getValueFromEvent={(forms: string[]) => {
                            setHasBusinessForm && setHasBusinessForm(forms.some((v) => bussForms.includes(v)));
                            return forms;
                        }}
                    >
                        <Checkbox.Group style={{ flexDirection: 'column' }}>
                            {formOptions.map((ft) => <Checkbox key={`${type}-ft-${ft}`} value={ft}>{ft}</Checkbox>)}
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={index != null ? [index, "yearList"] : "yearList"}
                        required
                        rules={[{ required: true, message: 'Tax Year is required.' }]}
                        getValueFromEvent={(yrs: number[]) => {
                            setMultiDisabled(yrs.some((v) => v > 0));
                            setSingleDisabled(yrs.includes(0));
                            return yrs;
                        }}
                    >
                        <Checkbox.Group
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <div className="flex-center-center">
                                <Checkbox value={0} className="mr-2" disabled={multiDisabled} />
                                <Form.Item
                                    name={index != null ? [index, "multi"] : "multi"}
                                    initialValue="Last 2 Years"
                                    noStyle
                                >
                                    <Select
                                        options={[{ value: 'Last 2 Years' }, { value: 'Last 3 Years' }]}
                                        defaultValue="Last 2 Years"
                                        style={{ width: 160 }}
                                        disabled={multiDisabled}
                                    />
                                </Form.Item>
                            </div>
                            {taxYears().map((ty) => <Checkbox key={`${type}-ty-${ty}`} disabled={singleDisabled} value={ty}>{ty}</Checkbox>)}
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

export default FormTypes;
