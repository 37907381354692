import { IBorrower } from "../../../utils/interfaces/borrower";
import { RequestV2 } from "../../../utils/interfaces/originv2";
import BorrowerButton from "./BorrowerButton";
import RequestButton from "./RequestButton";

interface ActionButtonParams {
    item: IBorrower | RequestV2;
    setOpen: (_: boolean) => void;
    setOpenCancel: (id: boolean) => void;
}

const ActionButton = ({
    item,
    setOpen,
    setOpenCancel,
}: ActionButtonParams) => (Object.hasOwn(item, 'id') ? (
    <BorrowerButton
        borrower={item as IBorrower}
        setOpen={setOpen}
    />
) : (
    <RequestButton
        request={item as RequestV2}
        setOpenCancel={setOpenCancel}
    />
));

export default ActionButton;
