import { DateTime } from "luxon";
import Table, { ColumnType } from "antd/es/table";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { RootState } from "../../configureStore";
import ActionButton from "../molecules/ActionButton";
import RIAButton from "../molecules/RIA/RIAButton";
import { RIAStatus } from "../../utils/interfaces/encompass";
import { RequestStatus, halfStr } from "../../utils/helpers";
import StatusCell from "../molecules/StatusCell";
import { IBorrower } from "../../utils/interfaces/borrower";

interface EncompassTableParams {
    setOpen: (_: boolean) => void;
    setOpenCancel: (_: boolean) => void;
}

const EncompassTable = ({
    setOpen,
    setOpenCancel,
}: EncompassTableParams) => {
    const borrowers = useSelector<RootState, { [id: string]: IBorrower }>(({ borrower }) => borrower.borrowers);
    const hasRia = useSelector<RootState, boolean>(({ encompass }) => Boolean(encompass?.origin?.isShowRIA));
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['encompass/GET_TXN_ORIGIN'] || status.loading['encompass/GET_ORIGIN']);
    const loaded = useSelector<RootState, boolean>(({ status }) => status.success['encompass/GET_ORIGIN']);

    const source = useMemo<IBorrower[]>(() => Object.values(borrowers), [borrowers]);

    const columns = useMemo<ColumnType<IBorrower>[]>(() => {
        const cols: ColumnType<IBorrower>[] = [
            {
                title: 'Borrower Name',
                dataIndex: 'name',
                className: 'nowrap',
                ellipsis: true,
                render: (name: string, val: IBorrower) => (val.id ? halfStr(name) : undefined),
            },
            {
                title: 'Form',
                dataIndex: 'formDescription',
            },
            {
                title: 'Tax Year',
                width: 96,
                dataIndex: 'taxYear',
            },
            {
                title: 'Order Date',
                dataIndex: 'createdTime',
                render: (createdTime: string) => (createdTime ? DateTime.fromISO(createdTime).toLocaleString(DateTime.DATE_FULL) : undefined),
            },
            {
                title: 'Delivery Date',
                dataIndex: 'delivery',
                render: (delivery: string, record: IBorrower) => (
                    delivery
                        ? DateTime.fromISO(delivery).toLocaleString(DateTime.DATE_FULL)
                        : record.id && record.latestAttempt
                            ? `Checked: ${DateTime.fromJSDate(record.latestAttempt).toLocaleString(DateTime.DATETIME_SHORT)}`
                            : undefined
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (status: string, item: IBorrower) => <StatusCell item={item} status={status as RequestStatus} setOpen={setOpen} />,
            },
            {
                title: 'Action',
                dataIndex: 'action',
                width: 72,
                render: (_action, item) => <ActionButton setOpen={setOpen} item={item} setOpenCancel={setOpenCancel} />,
            },
        ];

        if (hasRia) {
            cols.push({
                title: 'RIA',
                dataIndex: 'riaStatus',
                render: (riaStatus: RIAStatus, { riaInvitationAccepted, riaInvitationSent, id }) => (
                    <RIAButton
                        status={riaStatus}
                        riaInvitationAccepted={riaInvitationAccepted}
                        riaInvitationSent={riaInvitationSent}
                        id={id}
                    />
                ),
            });
        }
        return cols;
    }, [hasRia]);

    return !loaded ? (
        <Table
            style={{ marginTop: 16 }}
            columns={columns}
            dataSource={[]}
            loading
        />
    ) : (
        <Table
            style={{ marginTop: 16 }}
            columns={columns}
            dataSource={source}
            rowKey="requestId"
            key="id"
            expandable={{
                defaultExpandAllRows: true,
                childrenColumnName: 'requests',
            }}
            loading={loading}
        />
    );
};

export default EncompassTable;
