import { Col, Form, Radio, Row, Tooltip, message } from "antd";
import { useSelector } from "react-redux";

import Icon from "../atoms/Icon";
import { textToClipboard } from "../../utils/helpers";
import { RootState } from "../../configureStore";

interface HasIRSParams {
    hide?: boolean;
    onChange?: (_: boolean) => void;
}

const HasIRS = ({
    hide = false,
    onChange = (_: boolean) => { },
}: HasIRSParams) => {
    const enabled = useSelector<RootState, string[] | undefined>(({ borrower: { activeBorrower: ac, borrowers: b }, configuration: { actionReasons: ar } }) => ar[(b[ac || '']?.actionReason?.actionCode) || '']?.enabled);
    const isFix = useSelector<RootState, boolean>(({ borrower: { isFix: isF } }) => isF);

    return (
        <div style={{ display: hide ? 'none' : 'block' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h5>Borrower Has IRS Account</h5>
                <small className="mx-1" style={{ fontSize: '13px', fontStyle: 'normal', marginBottom: '8px', fontWeight: '400 !important' }}>
                    IRS account creation steps
                    {' '}
                    <a href="https://statwpublicfileshare.blob.core.windows.net/publicfileshare/IRS%20Account%20-%20Borrower%20Experience-%20Account%20Creation%20.pdf?sp=r&st=2024-07-10T18:25:03Z&se=2026-01-01T03:25:03Z&spr=https&sv=2022-11-02&sr=b&sig=swogAJimwTO9ZsSMKNSP0xKBaKm%2FVluhL9Yoa2R7sCA%3D" target="_blank" rel="noreferrer">IRS Account Creation</a>
                </small>
                <Tooltip title="Copy Url">
                    <Icon
                        className="mb-2"
                        name="CopyOutlined"
                        onClick={() => {
                            textToClipboard("https://statwpublicfileshare.blob.core.windows.net/publicfileshare/IRS%20Account%20-%20Borrower%20Experience-%20Account%20Creation%20.pdf?sp=r&st=2024-07-10T18:25:03Z&se=2026-01-01T03:25:03Z&spr=https&sv=2022-11-02&sr=b&sig=swogAJimwTO9ZsSMKNSP0xKBaKm%2FVluhL9Yoa2R7sCA%3D");
                            message.open({
                                type: 'success',
                                content: 'Url Copied',
                            });
                        }}
                    />
                </Tooltip>
            </div>
            <Form.Item
                name="hasIRS"
                getValueFromEvent={(b) => {
                    onChange(b.target.value);
                    return b.target.value;
                }}
            >
                <Radio.Group
                    style={{ width: "100%" }}
                    disabled={Boolean(isFix && enabled?.length)}
                >
                    <Row gutter={8}>
                        <Col span={12}>
                            <Radio
                                value={Boolean(true)}
                                className="ant-input py-1 px-2 form-section myi-0"
                            >
                                Yes
                            </Radio>
                        </Col>
                        <Col span={12}>
                            <Radio
                                value={false}
                                title="No"
                                className="ant-input py-1 px-2 mt-0 form-section myi-0"
                            >
                                No
                            </Radio>
                        </Col>
                    </Row>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default HasIRS;
